import React from 'react'
import { FaCheck } from 'react-icons/fa'

const AddAdvertPackageCard = ({data, checkedOptID, onChange, hueRotate}) => {
  return (
    <article className="addAdvertPackageCard" style={{filter: `hue-rotate(${hueRotate}deg)`}} >

        <div className="addAdvertPackageCard__img" style={{filter: `hue-rotate(-${hueRotate}deg)`}}>
            <img src={data.image} alt={data.title} />
        </div>

        <div className="addAdvertPackageCard__content">
            <h4 className='addAdvertPackageCard__content--title'> {data.title} </h4>
            <p className='addAdvertPackageCard__content--desc'> {data.desc} </p>
            <div className="addAdvertPackageCard__options">
                {
                    data.options.map(item => (
                        <label className={`addAdvertPackageCard__options--item ${ checkedOptID == item.id ? "checked" : "" }`} key={item.id}>
                            <input
                                type="radio" 
                                name={data.id} 
                                value={item.id}
                                checkedOptID={ checkedOptID == item.id }
                                onChange={() => onChange( data.id, item.id )}
                            />
                            { checkedOptID == item.id && <span> <FaCheck/> </span> }
                            { item.title }
                        </label>
                    ))
                }
            </div>
        </div>
        
    </article>
  )
}

export default AddAdvertPackageCard