import React from 'react'
import PlayerMarketCardImg from "../images/download.webp"

const PlayerMarketSectionContainer = () => {
    return (
        <>
            <div className='player_market_section_container'>
                <div className="player_market_section_card">
                    Dünyada ve Türkiye’de oyun severler tarafından sıklıkla tercih edilen oyunların sayısı gün geçtikçe artmaktadır. Kullanıcılar arası rekabetin de hızla artığı bu dönemde oyun karakterlerinizi ve hesabınızı geliştirmek isteyebilirsiniz. Bilgisayar veya mobil oyunları oynayacağınız sırada ek özelliklerle beraber karakterlerinizi yetenek, kostüm, kask, silah gibi ek özelliklerle farklı bir boyuta taşımanız mümkündür. Oyunlarda genellikle bu tarz ek özelliklere item veya skin denir. Bunların yanı sıra oynadığınız oyundan alacağınız keyfi maksimuma çıkarmak için epin alma yoluna gidebilir, oyunlarınızdaki bazı hamleleri kolaylıkla gerçekleştirirsiniz. Bu tarz özellikleri oyunlardan satın alabileceğiniz gibi daha uygun fiyatlara satın alabilme adına farklı platformları da deneyebilirsiniz. Item satış sitelerinden bu tarz özellikleri daha uygun fiyatlara alabilmeniz mümkündür. Gamesatış olarak sizlere yaptığımız kampanyalarımızla beraber istediğiniz item’ları daha uygun fiyatlardan alırsınız. Sizlere sunduğumuz Item sat hizmetimiz ile, oyun içerisindeki mağazalardan çok daha uygun fiyatlara sitemizden alma-satma imkanı sağlarız.

                    Skin, item, oyun parası alacağınız sırada satın alma işlemlerinizi yerine getireceğiniz platformlar da çok ciddi bir önem arz eder. Bazı platformlarda siparişi verilen skin’lerin hesaplara aktarılmadığı veya aktarıldıktan sonra hesaplarda sorun çıkarıldığı görülebilmektedir. Bu yüzden item satışı yapılacak olan siteler arasından birisini tercih edeceğiniz sırada mutlaka en güvenilir olan platformları tercih etmeniz gerekir.

                    Gold bar, Yang/Won, Cabal Alz gibi oyun içerisinden satın almanızın mümkün olmadığı oyun paralarını da sitemiz aracılığı ile güvenle alabilir ve anında kullanmaya başlayabilirsiniz. Sitemizden item, skin ve oyun parası alma yoluna giden kişiler hesaplarında hiçbir problemle karşılaşmadan siparişlerini verebilmektedir. Sitemizde item ve skin sunmuş olduğumuz bazı oyunlar şu şekildedir:

                    Knight online item
                    Knight online ring
                    Metin2 item
                    CS2 skin
                    Dota2 skin
                    Rust Skin
                    Bu oyunlar dışında birçok oyunda sizlere çeşitli skin ve item’ları sunmaya özen gösteriyoruz. Sıklıkla düzenlediğimiz büyük kampanyalarla bunları daha uygun fiyatlara alabilme imkanınız da bulunacaktır.

                    Güvenilir Oyun Hesabı Satın Alma
                    Oyunların büyük bir bölümü rekabet gerektiren oyunlar olduğu için oyundaki hesabınızın seviyesi ne kadar ilerdeyse rakipleriniz de o kadar iyi olacaktır. Oyun severlerin büyük bir bölümü bu yüzden iyi rakiplerle karşılaşarak oyunlarını ileri seviyede oynayabilme adına profesyonel oyun hesapları aramaktadır. Sitemiz hesap satışı yapacak olan kişilerle hesap satın almak isteyen kişileri bir araya getirmektedir. Yer alan hesapların hepsi sorunsuz hesaplar olduğu gibi alış satış işlemlerinizde sorun yaşanmaması adına sitemizin güvencesi altında bu işlemleri yerine getirebilmeniz mümkündür. Bu yüzden hem hesap satışlarınızı yapmak istediğiniz sırada hem de hesap satın al yoluna gideceğiniz evrede sitemizi tercih ederek hizmetlerden faydalanabilmeniz mümkündür.

                    Gamesatış olarak üyelerimize mağaza oluşturabilme imkanı sağlıyoruz. Bu şekilde hesap satmak isteyen kişiler hesaplarının özelliklerini belirterek satışa koyabilme imkanına sahip olur. Sitemiz altyapı olarak bütün üyelerine güvenilir ticaret sağlayan bir platform konumundadır. Bu yüzden gerek satışlarını yapacak olan kişiler gerekse alım yapacak olanlar alışveriş esnasında bir mağduriyetle karşı karşıya kalmayacaktır.

                    Ucuz Oyun Satın Alma
                    Oynamak istediğiniz bir oyun olduğu zaman bunu en uygun fiyatlardan alabilmek için araştırma yoluna gidersiniz. Oyun satın al işlemlerinin yapılabilmesi adına araştırma yaparken en uygun fiyatların sitemizde sizlere sunulmaktadır. Sitemizde yer alan ve satın alımlarınızı gerçekleştirebileceğiniz oyunlar şu şekildedir:

                    Black Desert Online
                    Knight Online
                    Pubg Mobile
                    CS2 Counter Strike 2
                    Rust Skin
                    Dota 2
                    League of Legends
                    Valorant
                    Oasis Game
                    Free Fire
                    Metin 2
                    Point Blank
                    Silkroad Online
                    Zula Online
                    Fifa 20
                    Fifa 21
                    Blizzard Battle.Net
                    Travian Games
                    Fortnite
                    Legends of Runeterra
                    PlayStation Network
                    BomBom
                    Bigpoint Games
                    Aeria Games
                    Apex Legends – PC
                    Hounds The Last Hope
                    Battle Teams 2
                    Rigor Z
                    Cross Fire
                    Cabal Online
                    Tango Live
                    Smite Online
                    Habbo
                    İstanbul Kıyamet Vakti
                    Conquer Online
                    Klanlar.org
                    Roblox
                    Borderlands 3
                    Metro Exodus
                    Minecraft
                    Age of Empires Definitive Edition
                    Football Manager 2021
                    Conqueror’s Blade
                    Genshin Impact
                    FaceIT
                    Sitemizde sizlere Türkiye’de severek oynanmakta olan bütün oyunları sunmaya dikkat ediyoruz. Ucuz oyun satın al imkanlarımızla beraber bu oyunları oynamak istediğiniz noktada sitemizden satın alımlarınızı en uygun fiyatlardan yerine getirebilirsiniz. Oyunlar sizlere sitemizde uygun fiyatlardan sunulmakta olduğundan dolayı her zaman istediğiniz oyunların siparişlerini verebilirsiniz. Siparişleriniz en kısa süre içerisinde hesaplarınıza aktarılacağından dolayı beklemeden oynama yoluna gidebilme imkanınız da bulunacaktır.

                    Oyun Satın Alırken Site Seçimi
                    Oyun satın al yoluna gidilmesi sırasında site seçimlerinizde dikkatli olmanız gerekir. Item satışlarında olduğu gibi hesap ve oyun satışları noktasında da güvenilir platformları seçmeniz gerekir. Aksi takdirde satın alımlarını gerçekleştireceğiniz oyunlar hemen hesabınıza transfer edilmeyeceğinden dolayı oynayamayabilirsiniz. Oyun satın almak için sitemizi tercih etmekte olan kişiler kendilerine sunmuş olduğumuz hizmetlerimizin aşağıdaki gibi olduğunu görebilir:

                    Hızlı ve sorunsuz teslimat. Oyun içerisinde yapılan teslimatlarınız sabah 09.00 ile gece 02.00 arasında gerçekleştirilmektedir.
                    SSL güvencesi ve 3D Secure ile korunmakta olan güvenli alışverişler
                    Diğer sitelere göre daha uygun fiyatlar üzerinden hizmet
                    Yüksek müşteri memnuniyeti
                    Ucuz oyun al hizmetlerimiz sayesinde oynamak istediğiniz oyunları en uygun fiyatlardan alarak değerlendirebilmeniz mümkündür. Özellikle siparişlerinizi müşteri hizmetlerimizin aktif olduğu sabah 09.00 ilen gece 02.00 arasında sipariş verdiğiniz takdirde satın alma işlemleriniz kısa süre içerisinde tamamlanacaktır.

                    Oyun Nereden Satın Alınır?
                    Oyun ve oyun hesapları satın alma yoluna gideceğiniz noktada özellikle hangi siteleri tercih etmeniz gerektiğini sorgularsınız. Bazı sitelerde oyun satılması ve oyun satın alınması gibi işlemler sunulurken tam olarak güvenilir bir ortam oluşmayabilir. Oyun satın alma siteleri arasında seçimlerinizi yerine getireceğiniz sırada sitemizde sizlere her zaman en güvenilir şekilde hizmet verdiğimizden dolayı kısa sürede ticaretlerinizi yerine getirebilirsiniz. Sitemizden oyun hesabı satmak istediğiniz sırada kısa süre içerisinde hesaplarınızı yayınlayabileceğiniz gibi hesap satın almak isteyen kişilerle de iletişime geçebilirsiniz. Satın alma ve satma işlemleriniz noktasında müşteri hizmetlerimizin çalışma saatleri içerisinde iletişime geçmeniz durumunda hemen sizlere dönüş yapılmaktadır.

                    Oyun Hesabı Satın Alırken Dikkat Edilmesi Gerekenler
                    Oyun hesaplarınızı yükseltmek ve daha iyi rakiplerle oynamak istediğiniz sırada oyun hesabı satın alma yoluna gidebilirsiniz. Fakat hesap satın alma işlemlerinizi yapacağınız sırada dikkat etmeniz gereken bazı noktalar bulunuyor. Hesaplarınızı özellikle güvenilir sitelerden almadığınız takdirde hesaplarda bir süre sonra sorunlar meydana gelebilmektedir. Bunun yanı sıra oyun hesabının değerini belirleyen bazı unsurlar vardır. Hesaplarda dikkat edebileceğiniz bazı noktalar şu şekildedir:

                    Hesabın seviyesi
                    Hesapta yer alan karakterler
                    Karakterin skinleri
                    Alınan galibiyet sayısı
                    Mağlubiyetler
                    Hesap satışlarının yapılması sırasında genellikle hesap özellikleri bölümünde bu tarz bilgiler yer alabilmektedir. Aynı zamanda hesap sahibine bu bilgiler yer almasa bile sorma yoluna gidebilmeniz mümkündür. Bazı hesaplarda oyun oynama süresi de önem arz eden noktalar arasındadır. Bu tarz bir oyun hesabı alacağınız sırada oynama zamanını da öğrenme yoluna gidebilirsiniz. Hesapların bütün özellikleri sizlere net şekilde sunulması gerekir. Böylelikle alacağınız hesap hakkında bilgi sahibi olabilmeniz mümkündür.

                    Oyun Parası Satın Alma
                    Oyun oynarken oyun içerisinde alışveriş yapacağınız sırada oyun paraları kullanmanız gerekebilmektedir. Oyunların içerisinden oyun parası satın almak, kullanıcıların banlanmasına sebep olabilir. Bu gibi durumları ortadan kaldırmak amacıyla oyun paralarını farklı sitelerden alma yoluna da gidebilmeniz mümkündür. Oyun paralarını alacağınız evrede en uygun siteleri seçmek isterseniz sitemizin sizlere son derece uygun bir şekilde hizmet verir. Hangi oyun için para almak isterseniz isteyin yapılan kampanyalarla beraber alımlarınızı yerine getirebilirsiniz. Oyun paraları oyunların içerisinde genel anlamda farklı isimlerle adlandırılabilmektedir. Bazı oyunlarda elmas, altın gibi değerli madenlerle oyun paralarının adlandırıldığını görüyoruz. Sitemizde almak istediğiniz Gold bar, Yang/Won, Cabal Alz gibi paraların siparişlerini buna göre verebilir ve hesabınıza aktarılmasını sağlayabilirsiniz. Oyun paralarınız hesaba aktarıldıktan sonra hemen kullanmaya başlama imkanınız da bulunmaktadır. Bu şekilde oyun içerisinde satın almak istediğiniz şeyler olduğu zaman hemen satın alabilirsiniz.

                    En İyi Bilgisayar Oyunları
                    Oyun tutkunları için gerçekten kaliteli bir oyunu oynayabilmek ciddi önem arz ederken bu kişiler bilgisayar oyunlarının arasında en iyilerini seçmeye de özen göstermektedir. Pc oyun satın al hizmetinden faydalanmak isteyen oyunseverler, farklı satın alma seçenekleri sunan, oyun çeşitliliği fazla ve güvenilir siteleri araştırma yoluna gidebilmektedir. Gamesatış olarak sizlere sunmuş olduğumuz oyunlar dünya standartlarında en fazla oynanmakta olan oyunlardır. Bunun yanı sıra oyun çeşitliliğinin fazla olması aramış olduğunuz bütün oyunları bulabilme imkanını da sizlere sağlar. Bu oyunları direk satın alarak hesaplarınıza yükleyip oynayabileceğiniz gibi hesap satın al yoluna giderek oyunu oynayan ve hesaplarını ilerletmiş olan kişilerin hesaplarını da satın alabilirsiniz. Bu sizlere belli bir seviyenin üzerinde oyunu oynamaya başlama imkanı sağlamaktadır. Oyun veya hesap satın alma yoluna gitmek tamamen sizin tercihinize kaldığı gibi sitemizde sizlere bu iki hizmette sunulmaktadır.

                    Oyun ve Hesap Ödemeleri Nasıl Yapılır?
                    Oyun alım satım gibi hizmetleri veren sitelerden alışverişlerinizi yapacağınız sırada bu sitelerin özellikleri değişim gösterebilmektedir. En fazla değişen özelliklere baktığımız zaman ise; ödeme yöntemleri siteden siteye göre değişim gösterebilmektedir. Bir sitenin ödeme işlemlerini en kısa sürede yaptırmaya ve buna bağlı olarak hesap transferlerine imkan sağlaması gerektiği gibi farklı ödeme yöntemlerini de sunmaktadır. Sitemizde sizlere sunmuş olduğumuz ödeme yöntemleri ise aşağıdaki gibidir:

                    Ininal kart
                    Kredi kartı
                    Banka transferi
                    Mobil ödeme
                    Bu ödeme yöntemlerini sizlere sunarken kredi kartıyla ödeme yapan müşterilerinize özel taksit seçenekleri de sağlıyoruz. Bu şekilde hesap alışverişlerinizi yaparken bütçenizi sarsmayacak şekilde taksitlere bölerek bu alışverişlerinizi yerine getirebilmeniz mümkündür. Aynı zamanda sitemizden yapmış olduğunuz ticaretleriniz sırasında sizlere belirttiğimiz iade ve iptal koşullarını karşılaması durumunda iadelerinizi ve sipariş iptallerinizi de gerçekleştirebilmeniz mümkündür. Ödeme yöntemlerinden hangisini tercih ederseniz edin sizlere en kısa sürede siparişlerinizi tamamlama imkanı verildiği için oyunlarınızı oynamak için beklemek zorunda da kalmazsınız.

                    İtem Fiyatları
                    Oyunlarınızı her zaman güçlü karakterler üzerinden oynamak istersiniz. Bu noktada karakterinizin daha güçlü hale gelebilmesi adına çeşitli silah,kalkan,kafalık,göğüslük, zırh gibi ekipmanlar alırken ayrı bir bütçe oluşturmanız gerekecektir. İtem fiyatları siteden siteye göre değişim gösterebildiği gibi aynı zamanda tercih edilen item’lara göre de farklılık gösterir. İtem’ın oyun içinde kullanıcıya kazandırdığı bonuslar, oyun için sağladığı faydalar gibi noktalar, item fiyatlarını etkilemekte olan unsurlar arasında yer almaktadır.

                    Bu şekilde kullanımlarının yerine getirilmesi adına bütün ayrıntılara dikkat edilmesi gerektiği gibi aynı zamanda item satışı yapmak istediğiniz zaman özelliklerini daha detaylı bir şekilde belirtmeniz kısa sürede ve gerçek değeri ile satılmasına yardımcı olacak unsurlardandır. Sitemiz genel olarak item satın alma işlemlerini yapacak olan kişiler için diğer platformlara göre daha uygun fiyatlar üzerinden hizmet verir. Böylelikle bütçenizi çok zorlamak zorunda kalmadan alımlarınızı yerine getirebilirsiniz.

                    Steam Hesap Satın Alma
                    Dijital dağıtım platformları arasında yer alan Steam özellikle çok oyunculu oyunlar oynayan kişiler için çok kullanışlı bir ara yüz sunmaktadır. Ülkemizde oyun severler için olmazsa olmazlar arasında yer aldığı gibi bütün dünyadan oyun severlerin kullanmakta olduğunu söyleyebiliriz. Bu özelliği ile uluslararası alanda oyuncularla karşı karşıya gelme imkanınız bulunur. Steam içerisinde birçok oyun yer almakta olduğu gibi bu oyunları daha uygun fiyatlara sitemizden de alabilirsiniz. Ek olarak sitemizde steam hesap satışı yapılabildiği için hesaplarınızı satmak istediğiniz zaman içerisindeki oyunlarla beraber satışlarını yapabilmeniz mümkündür. Hesap satın almak isteyen kişiler ise özellikle istedikleri oyunların yer aldığı hesapları satın alabilmek için sitemizdeki ilanları inceleyerek satın alımlarını gerçekleştirebilir. Bu hesapların sizlere güvenilir bir şekilde teslim edildiği sitemiz tarafından kontrol altında tutulmaktadır. Bu yüzden aldığınız hesaplardan sorun yaşama ihtimaliniz minimum seviyeye düşer.

                    Gift Card Satın Alma
                    Sevdiklerinize hediye vermek isterken özellikle oyun oynamayı seven kişilere gift card hediye etmeniz onlar için en iyi hediye olacaktır. Gift card’lar oyunlara ve platformlara göre değişim gösterirken kişinin oynadığı bir oyun için hediye etmeniz ise vereceğiniz en iyi hediyelerden birisi olmasını sağlayabilir. Gamesatis olarak sizlere çeşitli gift card’ları da bir arada sunduğumuz için hemen siparişlerinizi vererek isterseniz siz kullanabilirsiniz isterseniz de sevdiklerinize hediye edebilirsiniz. Sizlere sunmuş olduğumuz bazı gift card’lar ise şu şekildedir:

                    PlayStation UK Card
                    Battle Net Gift Card
                    Amazon Gift
                    Nintendo eShop Gift Cards
                    Xbox live hediye kartı US
                    Bunlar sitemizden satın alma yoluna gidebileceğiniz hediye kartlarının sadece bazılarıdır. Bunların yanı sıra Google Play Store kartları gibi çeşitli seçeneklerden de faydalanabilmeniz mümkündür. Kartları satın aldığınız zaman sizlere verilmekte olan kodları siz kullanabileceğiniz gibi aynı zamanda hediye olarak başka birisiyle de paylaşabilirsiniz. Tamamen sizin tercihinize kalmış olan durumlar arasındadır.

                    Oyunlarda epin Nasıl Kullanılır?
                    Oyun içerisinde sıklıkla duyabileceğiniz terimlerden birisi; e pin’dir. E pin kodu harf ve sayılarda oluşmaktadır ve sizlere oyun içerisinde bazı işlemlerinizi yerine getirirken kullanabileceğiniz imkanlar sağlamaktadır. Bu pinler oyunu geliştiren firmalar tarafından üretilirken sitelerden satın almış olduğunuz bazı özellikler sizlere epin şekilde sunulur. Siz de bu kodları oyun içerisinde girdiğiniz zaman almış olduğunuz özellikler veya oyun paraları hesabınıza aktarılmaktadır. Sitemizde sizlere sunmuş olduğumuz bazı hizmetlerimiz sırasında siparişleriniz sonrasında sizlere e pin verilebilmektedir. Verilmekte olan bu e pinleri oyundaki hesabınıza yüklediğiniz takdirde kısa süre içinde kullanmaya başlayabilir, herhangi bir sorunla karşılaşmadan epin avantajlarından faydalanabilirsiniz.

                    Epin satın al hizmetini sizlere sunmuş olduğumuz sırada 7/24 sipariş verebilme imkanınız bulunur. Diğer sitelerde olduğu gibi siparişlerin verilmesinden sonra kişileri uzun süre bekletme gibi durumlar söz konusu olmayacak ve en kısa süre içerisinde siparişleriniz hesabınıza aktarılacaktır. Bu şekilde ne zaman sipariş vermek isterseniz siparişlerinizi gerçekleştirebilir ve oyun hesaplarınıza yükleyerek kullanma yoluna gidebilirsiniz. Siparişleriniz oyun hesaplarınıza aktarıldıktan sonra bir problemle karşılaşma ihtimaliniz bulunmamaktadır. Fakat herhangi bir sorun oluşması durumunda müşteri hizmetlerimizle iletişime geçerek bu sorunlarınızı gidebilirsiniz. Müşteri hizmetlerimiz son derece ilgili bir şekilde hizmet verdiği gibi sizlere en kısa süre içerisinde dönüş yaparak yaşamış olduğunuz problemlerin hemen çözülmesini sağlayacaktır. Bu yüzden oyunlarınızı sitemizden gerçekleştirmiş olduğunuz siparişlerinizle hemen oynayabilirsiniz.
                </div>
                {/* <div className="player_market_section_card2">
                    <img src={PlayerMarketCardImg} alt="" />
                </div> */}

            </div>

            {/* <button className='player_market-btn'>
                Devamını görüntüle
            </button> */}
        </>
    )
}

export default PlayerMarketSectionContainer