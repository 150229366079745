import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaSun, FaMoon, FaAutoprefixer, FaWrench } from 'react-icons/fa';
import { FaGear, FaScrewdriverWrench } from 'react-icons/fa6';

const LightDarkBlogContainer = () => {
    const [isLightMode, setIsLightMode] = useState(false);

    // const [ theme, setTheme ] = useState(sessionStorage.getItem("theme") || "auto");

    // function handleSwitchTheme(theme) {
    //     sessionStorage.setItem("theme", theme);
    //     setTheme(theme);
    //     if ( theme != "auto" ) {
    //         document.documentElement.setAttribute('data-theme', theme);
    //     } else {
    //         document.documentElement.setAttribute('data-theme', "");
    //     }
    // }

    useEffect(() => {
        const colorSchemeDark = window.matchMedia('(prefers-color-scheme: light)');
        const schemeStorage = sessionStorage.getItem("isLightMode");

        if (!schemeStorage) {
            colorSchemeDark.matches ?
            setIsLightMode(true) :
            setIsLightMode(false);
        } else {
            if (schemeStorage == "true") {
                setIsLightMode(true);
            } else if (schemeStorage == "false") {
                setIsLightMode(false);
            }
        }
    }, [])

    useEffect(() => { 
        sessionStorage.setItem("isLightMode", isLightMode);

        if (isLightMode) {
            document.documentElement.setAttribute('data-theme', "light");
        } else {
            document.documentElement.setAttribute('data-theme', "dark");
        }
    }, [isLightMode])


    return (
        <div className={`light_dark_blog_container ${isLightMode ? 'dark' : 'light'}`}>
            <Link to={'/blog'}>Blog</Link>
            <div className={`toggle-container ${isLightMode ? 'dark' : 'light'}`} onClick={() => setIsLightMode(!isLightMode)}>
                <div className="toggle-button">
                    {isLightMode ? <FaSun /> : <FaMoon />}
                </div>
            </div>

            {/* <div className="themeSwitch">
                <button className={`themeSwitch__themes ${theme == "light" && "active"}`} title='Light' onClick={() => handleSwitchTheme("light")}>
                    <FaSun/>
                </button>
                <button className={`themeSwitch__themes ${theme == "auto" && "active"}`} title='Auto' onClick={() => handleSwitchTheme("auto")}>
                    <FaScrewdriverWrench/>
                </button>
                <button className={`themeSwitch__themes ${theme == "dark" && "active"}`} title='Dark' onClick={() => handleSwitchTheme("dark")}>
                    <FaMoon/>
                </button>

                <div className={`themeSwitch__active ${
                    theme == "light" ? "light" : 
                    theme == "auto" ? "auto" :
                    theme == "dark" && "dark"
                }`}
                ></div>
                
            </div> */}


        </div>
    );
};

export default LightDarkBlogContainer;
