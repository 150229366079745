import React, { useEffect, useState } from 'react'
import Input from '../Input'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InputCheck from '../InputCheck'

const NumberChange = ({userObj}) => {
    const [ numberInaccessible, setNumberInaccessible ] = useState(false);

    const [ form, setForm ] = useState(
        {
            prewNumber: userObj?.phone_number || "",
            newNumber: "",
            repeatNumber: "",
        }
    )

    useEffect(() => {
        setForm(
            {
                ...form,
                prewNumber: userObj.phone_number
            }
        )
    }, [userObj])

    function handleForm(e) {
        setForm(
            {
                ...form,
                [e.target.name] : e.target.value
            }
        )
    }

    console.log(form);

    function handleNumberInaccessible() {
        setNumberInaccessible(!numberInaccessible);
    }

  return (
    <div className="mailChange">
        <h2> Telefon Değiştir </h2>
        <form className="mailChange__form" onSubmit={(e) => e.preventDefault()}>
            <label>
                Şuanki Telefon Numaranız
                <Input
                    placeholder="Şuanki Telefon Numaranız"
                    value={form.prewNumber}
                    onChange={handleForm}
                    name='prewNumber'
                />
            </label>
            <label>
                <InputCheck chechbox checked={numberInaccessible} onChange={handleNumberInaccessible}/>
                Bu telefon numarasına erişimim yok
            </label>

            {
                numberInaccessible &&

                <div className="profileInfo__infoMessage">
                    <div className="profileInfo__infoMessage--icon">
                        <FontAwesomeIcon icon={faAddressCard}/>
                    </div>
                    <div className="profileInfo__infoMessage--text">
                        <h4>
                            Mevcut telefona erişimim yok!
                        </h4>
                        <p>
                            Telefon numaranıza erişiminizin olmadığınızı belirttiğiniz için güvenlik kodunuzu hesabınızda kayıtlı myangels.n1@gmail.com mail adresinize göndereceğiz.
                        </p>
                    </div>
                </div>
            }

            <label>
                Yeni Telefon Numaranız
                <Input
                    placeholder="Yeni Telefon Numaranız"
                    value={form.newNumber}
                    onChange={handleForm}
                    name='newNumber'
                />
            </label>

            <label>
                Tekrar Yeni Telefon Numaranız
                <Input
                    placeholder="Tekrar Yeni Telefon Numaranız"
                    value={form.repeatNumber}
                    onChange={handleForm}
                    name='repeatNumber'
                />
            </label>

            <button> Güncelle </button>

        </form>
    </div>
  )
}

export default NumberChange