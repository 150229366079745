import { faComments, faThumbsUp, faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

/* profile photo image */
import storeMainBoxPP from "../images/storeMainBoxPP.webp"
import badgeImg from "../images/level.webp"
import greenCheck from "../images/greenCheck.webp"

const StoreMainBox = ({adverts}) => {
  return (
    <article className='storeMainBox'>
        <div className="storeMainBox__content">
            <div className="storeMainBox__heading">
                <div className="storeMainBoxPP">
                    <img src={storeMainBoxPP} alt="" />
                </div>
                <div className="storeMainBox__heading--text">
                    <h5 className="storeMainBox__title"> Mclovin </h5>
                    <p className="storeMainBox__activity"> 6 saat önce aktifti. </p>
                </div>
            </div>
            <div className="storeMainBox__btn">
                <button className="storeMainBox__btn--primary">
                    <FontAwesomeIcon icon={faUserCheck} />
                    Takip Et
                </button>
                <button className="storeMainBox__btn--secondary">
                    <FontAwesomeIcon icon={faComments}/>
                    Sohbet
                </button>
            </div>
            <div className="storeMainBox__counts">
                <div>
                    <strong>21</strong>
                    <span>AKTİF İLAN</span>
                </div>
                <div>
                    <strong>355</strong>
                    <span>TAKİPÇİ</span>
                </div>
            </div>

            <div className="storeMainBox__userBadges">
                <span className="storeMainBox__userBadges--like">
                    <FontAwesomeIcon icon={faThumbsUp} />
                    1143
                </span>

                <div className='storeMainBox__userBadges--badge' style={{backgroundImage: `url(${badgeImg})`}}>
                    23
                </div>

                <div className='storeMainBox__userBadges--badge' style={{backgroundImage: `url(${greenCheck})`}}> </div>


            </div>

        </div>

        {
            adverts &&
            
            <div className="storeMainBox__adverts">

            </div>
        }
    </article>
  )
}

export default StoreMainBox