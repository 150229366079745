import React from 'react'
import ProfileDetailLeftContainer from './ProfileDetailLeftContainer'
import ProfileDetailRightContainer from './ProfileDetailRightContainer'
import { useSelector } from 'react-redux'

const ProfileDetailLeftRightContainer = () => {
    const { userObj } = useSelector(state => state.Data)
  return (
    <div className='profile_detail_left_right_container container'>
        <ProfileDetailLeftContainer userObj={userObj}/>
        <ProfileDetailRightContainer userObj={userObj}/>
    </div>
  )
}

export default ProfileDetailLeftRightContainer