import React from 'react'

/*         images          */
import coverPhoto1 from "../../images/CoverPhoto1.webp"
import coverPhoto2 from "../../images/CoverPhoto2.webp"
import coverPhoto3 from "../../images/CoverPhoto3.webp"
import coverPhoto4 from "../../images/CoverPhoto4.webp"
import coverPhoto5 from "../../images/CoverPhoto5.webp"
import coverPhoto6 from "../../images/CoverPhoto6.webp"




const CoverPhoto = () => {
  return (
    <div className="coverPhoto avatar">
        <h2> Kapak Fotoğrafı </h2>
        <div className="avatar__top">
            <div className="avatar__topLeft">
                <p>
                    Aşağıda bulunan varsayılan kapak fotoğraflarından birini seçebilirsin,
                    veya kendi kapak fotoğrafını yükleyebilirsin.
                </p>
                <input type="file" />
                <p className="avatar__topLeft--message">
                    En fazla 1340x300 boyutlarında olmalıdır.
                </p>
            </div>
            <div className="avatar__topRight">
                <strong> Mevcut Kapak Fotoğrafı </strong>
                <div className="avatar__topRight--img">
                    <img src={coverPhoto1} alt="" />
                </div>
            </div>
        </div>

        <div className="avatar__main">

            <div className="avatarBox active">
                <img src={coverPhoto1} alt="" />
            </div>

            <div className="avatarBox">
                <img src={coverPhoto2} alt="" />
            </div>

            <div className="avatarBox">
                <img src={coverPhoto3} alt="" />
            </div>

            <div className="avatarBox">
                <img src={coverPhoto4} alt="" />
            </div>

            <div className="avatarBox">
                <img src={coverPhoto5} alt="" />
            </div>

            <div className="avatarBox">
                <img src={coverPhoto6} alt="" />
            </div>

        </div>

        <button> Güncelle </button>
    </div>
  )
}

export default CoverPhoto