import { faCheck, faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const InputCheck = ({chechbox, radio, value, name, checked, onChange}) => {
  return (
    <div className={`inputCheck ${chechbox ? "checkbox" : (radio && "radio")}`}>
        <input
            type= { chechbox ? "checkbox" : (radio && "radio") }
            value={value}
            name={name}
            onChange={onChange}
        />
        { chechbox && checked && <FontAwesomeIcon icon={faCheck}/> }
        { radio && checked && <FontAwesomeIcon icon={faCircle}/> }
    </div>
  )
}

export default InputCheck