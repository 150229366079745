import React from 'react'
import { FaLink, FaUnlink } from 'react-icons/fa'

const LinkedAccountCard = ({img, icon, logoBackgroundColor, title, desc, linked=false, buttonText, onClick}) => {

  return (
    <div className={`linkedAccountCard ${linked ? "linked" : ""}`}>
        <div className='linkedAccountCard__box'>
            <div className="linkedAccountCard__img" style={{backgroundColor: logoBackgroundColor}}>
                { img && <img src={img} alt={title} /> }
                { icon && icon }
            </div>

            <div className="linkedAccountCard__title">
                <p> {title} </p>
                {  desc && <span> {desc} </span> }
            </div>
        </div>

        <button className={`linkedAccountCard__btn ${!linked ? "primary" : "secondary"}`} onClick={onClick}>
            { !linked ? <FaLink/> : <FaUnlink/> }
            {buttonText}
        </button>
    </div>
  )
}

export default LinkedAccountCard