import React from 'react'

const AccessRecords = () => {
  return (
    <div className="accessRecords">
        <h2> Erişim Kayıtlarım </h2>
        <p>
            Aşağıda yer alan tabloda üyeliğinize erişim sağlanan son 10 kayıt gösterilmektedir.
        </p>

        <table className='mainTable'>
            <thead>
                <tr>
                    <th> # </th>
                    <th> Tarih </th>
                    <th> IP Adresi </th>
                    <th> Detay </th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td> 27346210 </td>
                    <td> 18.08.2024 07:37:02 </td>
                    <td> 193.168.11.1 </td>
                    <td> Azerbaijan , Baku City , Baku  Uninet LLC </td>
                </tr>

                <tr>
                    <td> 27346210 </td>
                    <td> 18.08.2024 07:37:02 </td>
                    <td> 193.168.11.1 </td>
                    <td> Azerbaijan , Baku City , Baku  Uninet LLC </td>
                </tr>

                <tr>
                    <td> 27346210 </td>
                    <td> 18.08.2024 07:37:02 </td>
                    <td> 193.168.11.1 </td>
                    <td> Azerbaijan , Baku City , Baku  Uninet LLC </td>
                </tr>

                <tr>
                    <td> 27346210 </td>
                    <td> 18.08.2024 07:37:02 </td>
                    <td> 193.168.11.1 </td>
                    <td> Azerbaijan , Baku City , Baku  Uninet LLC </td>
                </tr>
            </tbody>
        </table>
    </div>
  )
}

export default AccessRecords