import React from 'react'
import img from '../../images/destination.webp'
import { Switch } from '@mui/material'

const AccountSecurity = () => {
  return (
    <div className="accountSecurity">
        <h2>Hesap Güvenliği</h2>

        <div className="accountSecurity__box">

            <div className="accountSecurity__card">
                <div className="accountSecurity__card--img">
                    <img src={img} alt="" />
                </div>
                <div className="accountSecurity__card--text">
                    <p>
                        E-Mail Güvenliği sistemini aktifleştirdiğinizde tüm giriş ve finansal işlemlerinizde mail olarak onayınız istenmektedir.
                    </p>
                    <div className='accountSecurity__card--check'>
                        <Switch/>
                        E-Mail Güvenliği
                    </div>
                </div>
            </div>

            <div className="accountSecurity__card">
                <div className="accountSecurity__card--img">
                    <img src={img} alt="" />
                </div>
                <div className="accountSecurity__card--text">
                    <p>
                        E-Mail Güvenliği sistemini aktifleştirdiğinizde tüm giriş ve finansal işlemlerinizde mail olarak onayınız istenmektedir.
                    </p>
                    <div className='accountSecurity__card--check'>
                        <Switch/>
                        E-Mail Güvenliği
                    </div>
                </div>
            </div>

            <div className="accountSecurity__card">
                <div className="accountSecurity__card--img">
                    <img src={img} alt="" />
                </div>
                <div className="accountSecurity__card--text">
                    <p>
                        E-Mail Güvenliği sistemini aktifleştirdiğinizde tüm giriş ve finansal işlemlerinizde mail olarak onayınız istenmektedir.
                    </p>
                    <div className='accountSecurity__card--check'>
                        <Switch/>
                        E-Mail Güvenliği
                    </div>
                </div>
            </div>

            <div className="accountSecurity__card">
                <div className="accountSecurity__card--img">
                    <img src={img} alt="" />
                </div>
                <div className="accountSecurity__card--text">
                    <p>
                        E-Mail Güvenliği sistemini aktifleştirdiğinizde tüm giriş ve finansal işlemlerinizde mail olarak onayınız istenmektedir.
                    </p>
                    <div className='accountSecurity__card--check'>
                        <Switch/>
                        E-Mail Güvenliği
                    </div>
                </div>
            </div>

        </div>

        <button> Güvenlik Ayarlarını Güncelle </button>

    </div>
  )
}

export default AccountSecurity