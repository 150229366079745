import React from 'react'
import { Link } from 'react-router-dom'

const StoresSection = (props) => {
  return (
    <section className='storesSection container'>
        <div className="storesSection__heading">

            <div className="storesSection__heading--title">
                <h3> {props.heading} </h3>
                {
                    props.desc &&
                    <p> {props.desc} </p>
                }
            </div>

            <div className='storesSection__heading--btn'>
                <Link to={props.headingBtnLink}>
                    {props.headingBtn}
                </Link>
            </div>

        </div>

        <div className="storesSection__main">
            {props.children}
        </div>

        {
            props.loadMore &&
            <div className="storesSection__loadMore">
                <button onClick={props.loadMoreOnclick}> {props.loadMore} </button>
            </div>
        }

    </section>
  )
}

export default StoresSection