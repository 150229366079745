import React, { useEffect, useRef } from 'react';
import { modalOverlayFunc } from '../../redux/MainReducer';
import { useDispatch, useSelector } from 'react-redux';
import { IoClose } from 'react-icons/io5';
import { getCategoryList } from '../../actions/MainAction';
import { Link } from 'react-router-dom';
import { CiSearch } from 'react-icons/ci';

const CategoryModal = () => {
    const dispatch = useDispatch();
    const { categoryList } = useSelector(state => state.Data);
    const modalRef = useRef();

    useEffect(() => {
        dispatch(getCategoryList());
    }, [dispatch]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                dispatch(modalOverlayFunc());
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dispatch]);

    console.log(categoryList);

    return (
        <div ref={modalRef} className="category_modal_container">
            {/* <div onClick={() => dispatch(modalOverlayFunc())} className='overlay'></div> */}
            <div className='container all_shops_search_container'>
                <form className='other_games_input'>
                    <input type="search" placeholder='Buradan kateqori arayabilirsiniz.' />
                    <button><CiSearch className='header_second_search' /></button>
                </form>
                <Link to={''}>Tüm kategorileri gör</Link>
            </div>
            <div className="category_modal_container_parent container">
                {categoryList?.map((data, i) => (
                    <Link to={''} key={i}>
                        <div className="category_modal_container_child">
                            <img src={data?.photo} alt="" />
                            <p>{data?.title}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default CategoryModal;
