import React, { useEffect, useState } from 'react'
import Input from '../Input'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InputCheck from '../InputCheck'

const MailChange = ({userObj}) => {
    const [ mailInaccessible, setMailInaccessible ] = useState(false);

    const [ form, setForm ] = useState(
        {
            prewMail: userObj.email,
            newMail: "",
            repeatMail: "",
        }
    )

    useEffect(() => {
        setForm(
            {
                ...form,
                prewMail: userObj.email
            }
        )
    }, [userObj])

    function handleForm(e) {
        setForm(
            {
                ...form,
                [e.target.name] : e.target.value
            }
        )
    }    

    function handleMailInaccessible() {
        setMailInaccessible(!mailInaccessible);
    }

  return (
    <div className="mailChange">
        <h2> Mail Değiştir </h2>
        <form className="mailChange__form" onSubmit={(e) => e.preventDefault()}>
            <label>
                Şuanki Mail Adresiniz
                <Input placeholder="Şuanki Mail Adresiniz" value={form.prewMail} onChange={handleForm} name='prewMail'/>
            </label>
            <label>
                <InputCheck chechbox checked={mailInaccessible} onChange={handleMailInaccessible}/>
                Bu mail adresine erişimim yok
            </label>

            {
                mailInaccessible &&

                <div className="profileInfo__infoMessage">
                    <div className="profileInfo__infoMessage--icon">
                        <FontAwesomeIcon icon={faAddressCard}/>
                    </div>
                    <div className="profileInfo__infoMessage--text">
                        <h4>
                            Mevcut mail adresime erişimim yok!
                        </h4>
                        <p>
                            Mail adresinize erişiminizin olmadığınızı belirttiğiniz için güvenlik kodunuzu hesabınızda kayıtlı 0775437800 numaraya SMS olarak göndereceğiz.
                        </p>
                    </div>
                </div>
            }

            <label>
                Yeni Mail Adresiniz
                <Input
                    placeholder="Yeni Mail Adresiniz" 
                    value={form.newMail} 
                    onChange={handleForm}
                    name= "newMail"
                />
            </label>

            <label>
                Tekrar Yeni Mail Adresiniz
                <Input
                    placeholder="Tekrar Yeni Mail Adresiniz"
                    value={form.repeatMail}
                    onChange={(e) => handleForm(e)}
                    name= "repeatMail"
                />
            </label>

            <button> Güncelle </button>

        </form>
    </div>
  )
}

export default MailChange