import React from 'react'
import MainFirstImg from "../images/valorant-hesap-58219.webp"

const MainFirstCard = () => {
  return (
    <div className='main_first_card'>
        <img src={MainFirstImg} alt="" />
    </div>
  )
}

export default MainFirstCard