import React from 'react'
import Input from '../Input'

const PasswordChange = () => {
  return (
    <div className="passwordChange">
        <h2> Şifre Değiştir </h2>
        <form className="passwordChange__form">
            <label>
                Şuanki Şifreniz
                <Input type="password"/>
            </label>

            <label>
                Yeni Şifreniz
                <Input type="password"/>
            </label>

            <label>
                Tekrar Yeni Şifreniz
                <Input type="password"/>
            </label>

            <button> Güncelle </button>
        </form>
    </div>
  )
}

export default PasswordChange