import React, { useEffect, useState } from 'react'

/* Images */

import billingImg1 from '../../images/billingImg1.webp'
import billingImg2 from '../../images/billingImg2.webp'
import billingImg3 from '../../images/billingImg3.webp'
import billingImg4 from '../../images/billingImg4.webp'
import communityImg from '../../images/level-badge.webp'
import summaryImg from '../../images/2125352.svg'

const ProfileSummary = ({userObj}) => {
    const siteURL = window.location;
    // const [nowShopAdress,setNowShopAdress]=useState(userObj.username);

    const [userData, setUserData] = useState(
        {
            username: userObj.username || "",
            userLink: ""
        }
    ) 
    const [ rangeSuccess, setRangeSuccess ] = useState(34); /*example: 34*/

    useEffect(() => {
        setUserData(
            {
                username: userObj.username,
                userLink: `${siteURL.origin}/profile/${userObj.username}`
            }
        )
    }, [userObj])

    function handleChange(value) {
        setUserData(
            {
                username: value,
                userLink: `${siteURL.origin}/profile/${value}`
            }
        )
    }    

  return (
    <div>
        <div className='profile_detail_right_container'>
            <h3>Mağaza Adresi Belirle</h3>
            <p>Merhaba Mansur, Hesabını güvenilir hesap statüsüne yükselttikten sonra mağaza adresi belirleme hakkı kazanabilirsin.
            Bu hakkını yalnızca 1 kez kullanabileceksin. Bu alandan yalnızca profilinizin adresini (URL) değiştirirsiniz. Kullanıcı adınız değişmemektedir.
            </p>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="profile_detail_label_inp">
                    <label htmlFor="adress">Şuanki Mağaza Adresiniz</label>
                    <input id='adress' type="text" value={userData.username} onChange={(e)=>handleChange(e.target.value)}/>
                </div>
                <div className="profile_detail_label_inp">
                    <label htmlFor="shop_profile">Oluşacak Mağaza Adresi</label>
                    <input id='shop_profile' type="text" value={userData.userLink} disabled/>
                </div>
                <button>
                    Güncelle
                </button>
            </form>
        </div>
        <div className="profileSummary">
            <div className="profileSummary__billing">
                <h3>Hesap Özeti</h3>
                <div className="profileSummary__billing--main">
                    <div className='profileSummary__billing--box'>
                        <div className="profileSummary__billing--boxImg">
                            <img src={billingImg1} alt="" />
                        </div>
                        <div className="profileSummary__billing--boxContent">
                            <strong> 0.00 TL </strong>
                            <span> Hesap Bakiyesi </span>
                        </div>
                    </div>
                    <div className='profileSummary__billing--box'>
                        <div className="profileSummary__billing--boxImg">
                            <img src={billingImg2} alt="" />
                        </div>
                        <div className="profileSummary__billing--boxContent">
                            <strong> 0 </strong>
                            <span> Satılan Toplam İlan </span>
                        </div>
                    </div>
                    <div className='profileSummary__billing--box'>
                        <div className="profileSummary__billing--boxImg">
                            <img src={billingImg3} alt="" />
                        </div>
                        <div className="profileSummary__billing--boxContent">
                            <strong> 0.00 TL </strong>
                            <span> Çekilebilir Tutar </span>
                        </div>
                    </div>
                    <div className='profileSummary__billing--box'>
                        <div className="profileSummary__billing--boxImg">
                            <img src={billingImg4} alt="" />
                        </div>
                        <div className="profileSummary__billing--boxContent">
                            <strong> 0.00 TL </strong>
                            <span> Toplam Kazanç </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="profileSummary__community">
                <h3> Topluluk Özeti </h3>
                <div className="profileSummary__community--box">
                    <div className="profileSummary__community--boxImg" style={{backgroundImage: `url(${communityImg})`}}>
                        <span>SEVIYE</span>
                        <strong>1</strong>
                    </div>
                    <div className="profileSummary__community--boxContent">
                        <div className="profileSummary__community--boxText">
                            <p>
                                <strong>+45 EXP </strong> 
                                seviye atlamak için gerekiyor
                            </p>
                            <span>0 SAHİP OLUNAN TOPLAM EXP</span>
                        </div>
                        <div className="profileSummary__community--range">
                            <div style={{width: `${rangeSuccess}%`}} className="profileSummary__community--rangeSuccess"></div>
                            <span> {rangeSuccess || 0}%</span>
                        </div>
                    </div>

                </div>
            </div>

            <div className="profileSummary__advert">
                <h3> İlan Özeti </h3>
                <div className="profileSummary__advert--box">
                    <div className="profileSummary__advert--img">
                        <img src={summaryImg} alt="" />
                    </div>
                    <h4> İlan bulunamadı </h4>
                    <p> Aktif ilanınız bulunmamaktadır. </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ProfileSummary