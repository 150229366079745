import React from 'react'

import img from "../../images/bank.svg"

const BankAccounts = () => {
  return (
    <div className="bankAccounts">
        <div className="bankAccounts__heading">
            <h2>Banka Hesaplarım</h2>
            <button> Banka Hesabı Ekle </button>
        </div>
        <div className="bankAccounts__main">
            <div className="bankAccounts__main--img">
                <img src={img} alt="" />
            </div>
            <div className="bankAccounts__main--text">
                <h3>
                    Hesabınıza kayıtlı banka hesabı bulunamadı
                </h3>
                <p>
                    Hesabınıza kayıtlı hiçbir banka hesabı bulunamadı.
                    Sağ üstte bulunan buton ile yeni bir banka hesabı ekleyebilirsiniz.
                </p>
            </div>
        </div>
    </div>
  )
}

export default BankAccounts