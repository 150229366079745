import React, { useEffect, useState } from 'react'
import { FaSearch } from 'react-icons/fa';

/* images */

import img1 from "../../images/valorant-random-hesap-635.webp"
 

const AddAdvertFirstBox = ({primary, secondary, data}) => {
    const [ search, setSearch ] = useState(""); 
    const [ filteredData, setFilteredData ] = useState(data)

    useEffect(() => {
        const filtered = data?.filter( i => i.title.toLowerCase().includes(search.toLowerCase()))
        console.log(filtered);
        // setFilteredData(filtered)
    }, [ search ])

    const arr = [3,3,3,3,3,3,3,3,3,3,3,2,3,3,3,3,3,3,3,3,3,3,3,3,4,3,3,3,3,3,3,3,3,3]; // for map

  return (
    <div className={`addAdvertFirstBox ${primary && "primary"} ${secondary && "secondary"}`}>
        <div className="addAdvertFirstBox__search">
            <input
                type="text" 
                placeholder='Filtreleme yapın...'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            <span><FaSearch/></span>
        </div>

        <div className="addAdvertFirstBox__content">

            <div className="addAdvertFirstBox__card">
                <div className="addAdvertFirstBox__card--img">
                    <img src={img1} alt="" />
                </div>
                <p className='addAdvertFirstBox__card--title'> Lorem, ipsum. </p>
            </div>

            {
                arr.map(() => (
                    <div className="addAdvertFirstBox__card">
                        <div className="addAdvertFirstBox__card--img">
                            <img src={img1} alt="" />
                        </div>
                        <p className='addAdvertFirstBox__card--title'> Lorem, ipsum. </p>
                    </div>
                ))
            }

        </div>
    </div>
  )
}

export default AddAdvertFirstBox