import React from 'react'
import AddAdvertFirstBox from './AddAdvertFirstBox'

const AddAdvertFirst = () => {
  return (
    <section className='addAdvertFirst'>

      <AddAdvertFirstBox/>


      {/* <div className="addAdvertFirst__app">

      </div> */}

    </section>
  )
}

export default AddAdvertFirst