import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import HeaderSearchInpProductCard from './HeaderSearchInpProductCard';

const HeaderSearchInpProducts = () => {
    const productsSearchList = useSelector(state => state.Data.productsSearchList);
    const navigate = useNavigate();

    // URL formatlama funksiyası
    const formatUrl = (title, id) => {
        const formattedTitle = title
            .replace(/\//g, '-')        // / işarəsini əvəz edir
            .replace(/\?/g, '')         // ? işarəsini çıxarır
            .trim()                     // Başda və sondakı boşluqları çıxarır
            .replace(/\s+/g, '')        // Aradakı boşluqları çıxarır
            .toLowerCase();             // Kiçik hərflərə çevirir

        return `/${formattedTitle}/${id}`;
    };

    const handleLinkClick = (url) => {
        navigate(url);
        window.location.reload();
    };

    return (
        <div className="header_search_inp_products">
            {productsSearchList.length > 0 ? (
                productsSearchList.slice(0, 4).map((data, i) => (
                    <div key={i} onClick={() => handleLinkClick(formatUrl(data.title, data.id))}>
                        <HeaderSearchInpProductCard data={data} />
                    </div>
                ))
            ) : (
                <div className="no-results">Axtarış nəticəsi tapılmadı.</div>
            )}
        </div>
    );
};

export default HeaderSearchInpProducts;
