import React from 'react'
import FooterContactBox from './FooterContactBox'
import { Link } from 'react-router-dom'

const FooterContactSection = () => {
    const contactInfos = [
        {
            id: 1,
            title: "YASAL BİLGİLENDİRME",
            heading: "Yer Sağlayıcı Bilgilendirmesi",
            desc: "İtemSatış.com sitemiz, ALFATEK Yazılım Danışmanlık firması bünyesinde 5651 Sayılı Kanun'un 5. Maddesine tanımlanan şekliyle “yer sağlayıcı” olarak hizmet vermektedir."
        },
        {
            id: 2,
            title: "YASAL & HUKUKİ TEMSİLCİ",
            heading: "YG Legal Hukuk Bürosu",
            desc: "İstiklal Mah. Muhsin Yazıcıoğlu Bulvarı No : 71 / 203 İç Kapı No : 36 Aşoğlu Plaza Serdivan / Sakarya KEP Adresi : taha.genc@hs01.kep.tr"
        },
        {
            id: 3,
            title: "MARKA & KİŞİLİK HAKLARI SAHİBİ",
            heading: "ALFATEK Yazılım Danışmanlık LTD. ŞTİ.",
            desc: "Esentepe Mah. Akademiyolu Cad. Sakarya Üniv. Teknoloji Geliştirme Bölgeleri D Blok Serdivan / Sakarya"
        },
        {
            id: 4,
            title: "İŞLETMECİ ŞİRKET",
            heading: "Alphapins Bilişim Hizmetleri LTD.",
            desc: "Serbest Liman ve Bölge Posta Kut. No : 1566 Gazimağusa / KKTC , SLBT NO: 1578 İletişim : info@alphapins.net"
        },
        
    ]

  return (
    <div className='footer__contact container'>
        {
            contactInfos.map((item) => (
                <Link to="#" key={item.id}>
                    <FooterContactBox key={item.id} data={item}/>
                </Link>
            ))
        }
    </div>
  )
}

export default FooterContactSection