import React from 'react'
// import { IoRocketOutline } from "react-icons/io5";
import IlanPazariFooter from "../images/ilanpazariFooter.webp"
import { FaBasketShopping } from 'react-icons/fa6'


const FooterFirstCard = () => {
    return (
        <div className='footer_first_card'>
            {/* <IoRocketOutline className='footer_first_icon'/> */}
            <img src={IlanPazariFooter} alt="" />
            <div>
                <h5>İlan Pazarı</h5>
                <p>Binlerce ilan arasından seçimini yap ve hemen satın al.</p>
                <button> <FaBasketShopping/> İlan Pazarı</button>
            </div>
        </div>
    )
}

export default FooterFirstCard