import React from 'react'
import OtherHeader from '../components/OtherHeader'
import FooterFirstContainer from '../components/FooterFirstContainer'
import FooterSecondContainer from '../components/FooterSecondContainer'
import FooterThirdContainer from '../components/FooterThirdContainer'
import FooterEndContainer from '../components/FooterEndContainer'
import GiveawaySection from '../components/GiveawaySection'
import GiveawayCard from '../components/GiveawayCard'

const Giveaway = ({ otherPageBanner,banners }) => {
    return (
        <div>
            <OtherHeader otherHeaderPagesLink="Çekilişler" banners={banners} otherPageBanner={otherPageBanner} />
            <main>
                
                <GiveawaySection heading="Aktif Çekilişler" sort>
                    <GiveawayCard detailButton/>
                    <GiveawayCard detailButton/>
                    <GiveawayCard detailButton/>
                    <GiveawayCard detailButton/>
                    <GiveawayCard detailButton/>
                    <GiveawayCard detailButton/>
                    <GiveawayCard detailButton/>
                    <GiveawayCard detailButton/>
                    <GiveawayCard detailButton/>
                    <GiveawayCard detailButton/>
                    <GiveawayCard detailButton/>
                    <GiveawayCard detailButton/>
                </GiveawaySection>

                <GiveawaySection heading="Tamamlanan Çekilişler" loadMore>
                    <GiveawayCard/>
                    <GiveawayCard/>
                    <GiveawayCard/>
                    <GiveawayCard/>
                    <GiveawayCard/>
                    <GiveawayCard/>
                    <GiveawayCard/>
                    <GiveawayCard/>
                </GiveawaySection>



            </main>
            <footer>
                <div className="footer_parent_container">
                    <FooterFirstContainer />
                    <FooterSecondContainer />
                    <FooterThirdContainer />
                </div>
                <FooterEndContainer />

            </footer>
        </div>
    )
}

export default Giveaway