import React, { useState } from 'react'

import img from "../../images/2876508.svg"
import InputCheck from '../InputCheck'

const BalanceMovements = () => {
    const [ onlySystem, setOnlySystem ] = useState(false);

  return (
    <div className="balanceMovements bankAccounts">
        <div className="bankAccounts__heading">
            <h2>Bakiye Hareketleri</h2>
            <label>
                <InputCheck chechbox checked={onlySystem} onChange={() => setOnlySystem(!onlySystem)}/>
                Yalnızca Sistem Hareketlerini Göster
            </label>
        </div>
        <div className="bankAccounts__main">
            <div className="bankAccounts__main--img">
                <img src={img} alt="" />
            </div>
            <div className="bankAccounts__main--text">
                <h3>
                    Bakiye hareketiniz bulunamadı.
                </h3>
                <p>
                    Hesabınıza ait hiçbir bakiye hareketi bulunmamaktadır.
                </p>
            </div>
        </div>
    </div>
  )
}

export default BalanceMovements