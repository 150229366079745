import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

const Input = ({value="", onChange=() => {}, placeholder="", type="text", name=""}) => {
    const [ visibility, setVisibility ] = useState(false);

    function handleChangeVisibility(e) {
        e.preventDefault();
        setVisibility(!visibility)
    } 

  return (
    <div className="inputBox">
        <input
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            type={
                type != "password" ?
                type :
                (visibility ?
                "text" :
                "password") 
            }
        />

        {
            type == "password" && (visibility ?
                <button onClick={ handleChangeVisibility }>
                    <FontAwesomeIcon icon={faEye}/>
                </button>
                :
                <button onClick={ handleChangeVisibility }>
                    <FontAwesomeIcon icon={faEyeSlash}/>
                </button>
            )
        }
    </div>
  )
}

export default Input