import React from 'react'
import HeaderFirstContainer from './HeaderFirstContainer'
import HeaderSecondContainer from './HeaderSecondContainer'
import HeaderThirdContainer from './HeaderThirdContainer'
import OtherHeaderPagesLink from './OtherHeaderPagesLink'

const OtherHeaderDetail = ({otherHeaderPagesLink}) => {
    return (
        <header>
            <HeaderFirstContainer />
            <HeaderSecondContainer />
            <HeaderThirdContainer />
            <OtherHeaderPagesLink otherHeaderPagesLink={otherHeaderPagesLink} />
            {/* <OtherHeaderMainContainer banners={banners} otherPageBanner={otherPageBanner} otherHeaderPagesLink={otherHeaderPagesLink} /> */}
            {/* <OtherHeaderEndContainer otherHeaderPagesLink={otherHeaderPagesLink}/> */}
        </header>
    )
}

export default OtherHeaderDetail