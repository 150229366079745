import React from 'react'
import OtherHeader from '../components/OtherHeader'
import FooterFirstContainer from '../components/FooterFirstContainer'
import FooterSecondContainer from '../components/FooterSecondContainer'
import FooterThirdContainer from '../components/FooterThirdContainer'
import FooterEndContainer from '../components/FooterEndContainer'
import { useParams } from 'react-router-dom'
import ShopProfileSectionFirst from '../components/ShopProfileSectionFirst'

const ShopProfile = ({ otherPageBanner,banners }) => {
    const {id}=useParams()
    console.log(id);
    return (
        <div>
            <OtherHeader banners={banners} otherHeaderPagesLink="Shop profile" otherPageBanner={otherPageBanner} />
            <main>
                <section>
                    <ShopProfileSectionFirst id={id}/>
                </section>
            </main>
            <footer>
                <div className="footer_parent_container">
                    <FooterFirstContainer />
                    <FooterSecondContainer />
                    <FooterThirdContainer />
                </div>
                <FooterEndContainer />

            </footer>
        </div>
    )
}

export default ShopProfile