import React from 'react'
import FooterFirstCard from './FooterFirstCard'

const FooterFirstContainer = () => {
    return (
        
            <div className='footer_first_container container'>
                <FooterFirstCard />
                <FooterFirstCard />
                <FooterFirstCard />
                <FooterFirstCard />
            </div>
        

    )
}

export default FooterFirstContainer