import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaCaretDown, FaGift, FaShoppingBag, FaLaptopCode, FaStore } from 'react-icons/fa';
import { TbCategoryFilled } from 'react-icons/tb';
import { FaBasketShopping } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { categoryModalFunc } from '../redux/MainReducer';
import CategoryModal from './modals/CategoryModal';
import HeaderSecondsButtons from './HeaderSecondsButtons';
import AddAnnounce from './AddAnnounce';

const HeaderThirdContainer = ({ isSearchVisible, toggleSearchVisibility }) => {
    const [isActive, setIsActive] = useState(false);
    const dispatch = useDispatch();
    const { categoryModal } = useSelector(state => state.Data);

    const barsHandle = () => {
        setIsActive(!isActive);
    };

    return (
        <nav className='header_nav'>
            <div className='container header_third'>
                <ul className={`header_third_container ${isActive ? 'active' : ''}`}>
                    <li onClick={() => dispatch(categoryModalFunc())}><Link><TbCategoryFilled className='header_third_nav_icon' /> <span><span>Kategoriler</span> <FaCaretDown /></span></Link></li>
                    <li><Link to={'/game-shop'}><FaBasketShopping className='header_third_nav_icon' /> Oyuncu Pazarı</Link></li>
                    <li><Link to={'/game-shop'}><FaShoppingBag className='header_third_nav_icon' /> Alım İlanları</Link></li>
                    <li><Link to={'/giveaway'}><FaGift className='header_third_nav_icon' /> Çekilişler</Link></li>
                    <li><Link to={'/shops'}><FaStore className='header_third_nav_icon' /> Mağazalar</Link></li>
                    {/* <li><Link to={'/game-sale-project'}><FaLaptopCode className='header_third_nav_icon' /> Yazılım</Link></li> */}
                </ul>

                <FaBars onClick={barsHandle} className='bars_icon' />
                {/* <HeaderSecondsButtons
                    isSearchVisible={isSearchVisible}
                    toggleSearchVisibility={toggleSearchVisibility}
                /> */}
                <AddAnnounce />
            </div>
            {categoryModal && <CategoryModal />}
        </nav>
    );
};

export default HeaderThirdContainer;
