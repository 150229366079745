import React from 'react'
import OtherHeader from '../components/OtherHeader'
import FooterFirstContainer from '../components/FooterFirstContainer'
import FooterSecondContainer from '../components/FooterSecondContainer'
import FooterThirdContainer from '../components/FooterThirdContainer'
import FooterEndContainer from '../components/FooterEndContainer'
import StoresSection from '../components/StoresSection'
import StoreMainBox from '../components/StoreMainBox'

const Stores = ({ otherPageBanner,banners }) => {
    return (
        <div>
            <OtherHeader otherHeaderPagesLink="Mağazalar" banners={banners} otherPageBanner={otherPageBanner} />
            <main>
                
                <StoresSection 
                    heading="ÖNE ÇIKAN MAĞAZALAR"
                    headingBtn="Satıcı Olma Rehberi"
                    loadMore="Daha fazla öne çıkan mağaza göster"
                >
                    <StoreMainBox/>
                    <StoreMainBox/>
                    <StoreMainBox/>
                    <StoreMainBox/>
                    <StoreMainBox/>
                    <StoreMainBox/>
                    <StoreMainBox/>
                    <StoreMainBox/>

                </StoresSection>

                <StoresSection 
                    heading="EN ÇOK SATIŞ YAPAN MAĞAZALAR"
                    desc="Bu listelemede son 30 gün baz alınmıştır."
                    headingBtn="Tüm mağazalar"
                >
                    <StoreMainBox/>
                    <StoreMainBox/>
                    <StoreMainBox/>
                    <StoreMainBox/>
                    <StoreMainBox/>
                    <StoreMainBox/>
                    <StoreMainBox/>
                    <StoreMainBox/>

                </StoresSection>

                <StoresSection 
                    heading="EN YENİ MAĞAZALAR"
                    headingBtn="Tüm mağazalar"
                >
                    <StoreMainBox/>
                    <StoreMainBox/>
                    <StoreMainBox/>
                    <StoreMainBox/>

                </StoresSection>

            </main>
            <footer>
                <div className="footer_parent_container">
                    <FooterFirstContainer />
                    <FooterSecondContainer />
                    <FooterThirdContainer />
                </div>
                <FooterEndContainer />

            </footer>
        </div>
    )
}

export default Stores