import React from 'react'

const TrendCategoryCard = ({data}) => {
  return (
    <div className="slide-content">
      <div
        className="slide-image"
        style={{ backgroundImage: `url(${data.image})` }}
      />
      <div className="slide-txt">
        <h3>{data.title}</h3>
        <p>{data.description}</p>
      </div>
    </div>
  )
}

export default TrendCategoryCard