import React, { useState } from 'react';
import Img from "../images/8daf9bd5bb58cc1ea319ddb61929af94766ee6c1.webp";
import { RiUserFollowFill } from "react-icons/ri";
import { IoIosChatbubbles } from "react-icons/io";
import { BiLike } from 'react-icons/bi';
import axios from 'axios';
import { baseUrl } from '../MAIN_API';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';

const AllShopsCard = ({ data }) => {
    const navigate = useNavigate();
    const [isFollowing, setIsFollowing] = useState(false);

    const followShop = (id) => {
        const data = {
            shop: id
        };
        axios(`${baseUrl}accounts-shop/follow/create`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token_game')}`
            },
            data
        }).then(resp => {
            console.log(resp);
            if (resp.status === 201) {
                Swal.fire({
                    title: "Takib edildi",
                    icon: "success",
                    confirmButtonText: "OK",
                }).then(function () {
                    setIsFollowing(true);
                    window.location.reload();
                });
            }
        }).catch(err => {
            Swal.fire({
                title: "Error",
                text: "Takib edilmedi",
                icon: "error",
                confirmButtonText: "OK",
            });
        });
    };

    const unfollowShop = (id) => {
        axios(`${baseUrl}accounts-shop/follow/delete/${id}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token_game')}`
            }
        }).then(resp => {
            console.log(resp);
            if (resp.status === 204) {
                Swal.fire({
                    title: "Takipten çıkarıldı",
                    icon: "success",
                    confirmButtonText: "OK",
                }).then(function () {
                    setIsFollowing(false);
                    window.location.reload();
                });
            }
        }).catch(err => {
            Swal.fire({
                title: "Error",
                text: "Takipten çıkarılamadı",
                icon: "error",
                confirmButtonText: "OK",
            });
        });
    };

    return (
        <div className='all_shops_card'>
            <Link to={`/shop-profile/${data.id}`}>
                <div className="all_shops_card_header">
                    <img src={data?.cover} alt="" />
                    <div className="all_shops_card_header_content">
                        <p>{data?.name}</p>
                        <span>2 saat önce aktifti.</span>
                    </div>
                </div>
            </Link>

            <div className="all_shops_card_middle">
                {data?.is_following? (
                    <button onClick={() => followShop(data?.id)}><RiUserFollowFill /> Takib Et</button>
                ) : (
                    <button onClick={() => unfollowShop(data?.id)}>Takibi bırak</button>
                   
                )}
                <button><IoIosChatbubbles /> Sohbet</button>
            </div>
            <div className="all_shops_card_footer">
                <div>
                    <p>37</p>
                    <span>AKTİF İLAN</span>
                </div>
                <div>
                    <p>{data?.followers}</p>
                    <span>TAKİPÇİ</span>
                </div>
            </div>
            <div className="all_shops_card_footer_buttons">
                <div><BiLike />62276</div>
                <div>182</div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

export default AllShopsCard;
