import React from 'react'
import ProfileDetailMainHeader from './ProfileDetailMainHeader'
import ProfileDetailMainSecond from './ProfileDetailMainSecond'

const ProfileDetailMain = () => {
    return (
        <div className="shop_profile_section_first container">
            <ProfileDetailMainHeader />
            <ProfileDetailMainSecond />
        </div>
    )
}

export default ProfileDetailMain