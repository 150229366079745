import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import ReactSelect from 'react-select'

const ProfileInfo = () => {
    const cities = [
        { value: 'ocean', label: 'Ocean' },
        { value: 'blue', label: 'Blue' },
        { value: 'purple', label: 'Purple' },
        { value: 'red', label: 'Red' },
        { value: 'orange', label: 'Orange' },
        { value: 'yellow', label: 'Yellow' },
        { value: 'green', label: 'Green' },
        { value: 'forest', label: 'Forest' },
        { value: 'slate', label: 'Slate' },
        { value: 'silver', label: 'Silver' },
    ];


  return (
    <div className="profileInfo">
        <h2> Profil Bilgileri </h2>

        <div className="profileInfo__infoMessage">
            <div className="profileInfo__infoMessage--icon">
                <FontAwesomeIcon icon={faUserSecret}/>
            </div>
            <div className="profileInfo__infoMessage--text">
                <h4>
                    Bilgilerinizi doldururken dikkatli olun!
                </h4>
                <p>
                    Burada yer alan bilgileriniz diğer tüm kullanıcılar tarafından görülebilir olacaktır.
                    Bu yüzden bilinmesini istemediğiniz bilgileri doldurmayınız.
                </p>
            </div>
        </div>

        <form className='profileInfo__form'>
            <label>
                Hakkında
                <textarea name="aboutYourself" placeholder='Kendin hakkında kısa bir cümle yazmak istermisin ?'></textarea>
            </label>
            <label>
                Yaşadığın Şehir

                <ReactSelect
                    className="selectOption"
                    classNamePrefix="select"
                    defaultValue={cities[0]}
                    isClearable={true}
                    isSearchable={true}
                    name="city"
                    options={cities}
                    classNames={{
                        control: (select) => select.isFocused ? "selectFocus" : "select"
                    }}
                />

            </label>

            <button> Güncelle </button>

        </form>
    </div>
  )
}

export default ProfileInfo