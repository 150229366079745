import React from 'react'
import { FaShoppingCart } from "react-icons/fa";

import DealsDaysImg from "../images/brawl-stars-88-elmas-183.webp"


const DealsDaysCard = ({withoutBtn, data}) => {
  return (
    <div className='deals_days_card'>
        <div className="deals_days_card_header">
            <img src={DealsDaysImg} alt="" />
            <span>25% <br />İNDİRİM</span>
        </div>
        <div className="deals_days_card_content">
            <h4>Brawl Stars</h4>
            <p>Brawl Stars 88 Elmas</p>
            <div className="deals_days_card_content_sale">
                <p>149.99 TL</p>
                <span>199.99 TL</span>
            </div>
            {
              !withoutBtn &&
              <button><FaShoppingCart /> <span>Satın Al</span></button>
            }
        </div>
    </div>
  )
}

export default DealsDaysCard