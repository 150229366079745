import React from 'react'

const GiftCenter = () => {
  return (
    <div className="giftCenter accessRecords">
        <h2> Gönderilen Hediyeler </h2>
        <p>
            Aşağıda yer alan tabloda daha önce arkadaşlarınıza gönderdiğiniz hediyeler ve ilgili bilgiler listelenmektedir.
        </p>

        <table className='mainTable'>
            <thead>
                <tr>
                    <th> # </th>
                    <th> Ürün </th>
                    <th> Kime </th>
                    <th> E-Posta </th>
                    <th> Hediye Mesajınız </th>
                    <th> Toplam Ücret </th>
                    <th> Tarih </th>
                </tr>
            </thead>

            <tbody>
                
            </tbody>
        </table>
    </div>
  )
}

export default GiftCenter