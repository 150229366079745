import React, { useEffect, useRef } from 'react';
// import { BsCreditCardFill } from "react-icons/bs";
import { FaCoins, FaHeart, FaSignOutAlt, FaStore, FaUser, FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { handleLoginRegister } from '../redux/MainReducer';
import { IoNotificationsOutline, IoWalletOutline } from "react-icons/io5";
import { getUserObj } from '../actions/MainAction';
import Avatar from "../images/avatar-3637425_640.webp";
import { useNavigate } from 'react-router-dom';
import { HiMiniChatBubbleLeftRight } from "react-icons/hi2";
import { FiSearch } from 'react-icons/fi';
import { FaRegCreditCard } from "react-icons/fa";
import { FaBasketShopping } from 'react-icons/fa6';
import { HiOutlineSupport } from 'react-icons/hi';

const HeaderSecondsButtons = ({ isSearchVisible, toggleSearchVisibility }) => {
  const profileDetails = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let getAccess = localStorage.getItem("access_token_game");

  const { userObj } = useSelector(state => state.Data);
  console.log(userObj);

  useEffect(() => {
    dispatch(getUserObj());
  }, [dispatch]);

  const avatarToggleFunc = () => {
    profileDetails.current.classList.toggle("profile_details_active");
  };

  const handleClickOutside = (event) => {
    if (profileDetails.current && !profileDetails.current.contains(event.target)) {
      profileDetails.current.classList.remove("profile_details_active");
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const exitBtn = () => {
    localStorage.removeItem('access_token_game');
    localStorage.removeItem('loggedInEmail');
    window.location.reload();
  };

  const myAnnouncementsBtn = () => {
    navigate("/my-announcements");
  };

  const myFavoriesBtn = () => {
    navigate("/my-favories");
  };

  const detailProfileHandle=(username)=>{
    navigate(`/profile/${username}`)
  }

  return (
    <div className='header_seconds_buttons'>
      {getAccess ? (
        <div className='header_seconds_first_element'>
          <div>
            <IoWalletOutline  className='wallet_icon'/>
            <p>{userObj.balance} ₼</p>
          </div>

          <HiMiniChatBubbleLeftRight className='header_seconds_first_element_chat' />
          <IoNotificationsOutline className='header_seconds_first_element_notification' />
        </div>
      ) : (
        <button className='sign_btn' onClick={() => dispatch(handleLoginRegister())}>
          <FaUser /> <span>Giriş/Kayıt</span>
        </button>
      )}

      {getAccess ? (
        <div className="profile_buttons">
          <div className="profile_img" onClick={avatarToggleFunc}>
            <img src={userObj.avatar ? userObj.avatar : Avatar} alt="Avatar" />
          </div>
          <div ref={profileDetails} className="profile_details">
            <div className="profile_details_user" onClick={()=>detailProfileHandle(userObj?.username)}>
              <h4>{userObj.first_name} {userObj.last_name}</h4>
              <p>{userObj.balance} ₺</p>
            </div>
            <ul className="profile_details_buttons">
              <li><FaCoins className='header_third_nav_icon' /> +BAKİYE YÜKLE</li>
              <li><FaRegCreditCard className='header_third_nav_icon' /> Para çek</li>
              <li><FaUserCircle className='header_third_nav_icon' /> Profilim</li>
              <li><FaBasketShopping className='header_third_nav_icon' /> Siparişlerim</li>
              <li onClick={myAnnouncementsBtn}><FaStore className='header_third_nav_icon' /> İlanlarım</li>
              <li onClick={myFavoriesBtn}><FaHeart className='header_third_nav_icon' /> Favori ilanlarım</li>
              <li><HiOutlineSupport className='header_third_nav_icon' /> Destek sistemi</li>
              <li onClick={exitBtn}><FaSignOutAlt className='header_third_nav_icon' /> Çıkış</li>
            </ul>
          </div>
        </div>
      ) : null}

      <FiSearch
        className='header_mobile_search_icon'
        onClick={toggleSearchVisibility}
        style={{ cursor: 'pointer' }}
      />
    </div>
  );
};

export default HeaderSecondsButtons;
