import React from "react";
import mmo from "../images/mmo-770.webp";
import genshin from "../images/genshin-impact-913.webp";
import platform from "../images/platform-909.webp";
import riot from "../images/riotpoints-748.webp";
import steam from "../images/steam-296.webp";
function NewSectionFlex() {
  return (
    <div className="flex_container">
      <div className="flex_left">
        <div className="flex_item small">
          <img src={mmo} alt="mmo" />
        </div>
        <div className="flex_item small">
          <img src={steam} alt="steam" />
        </div>
      </div>
      <div className="flex_center">
        <div className="flex_item large">
          <img src={platform} alt="platform" />
        </div>
      </div>
      <div className="flex_right">
        <div className="flex_item small">
          <img src={riot} alt="riot" />
        </div>
        <div className="flex_item small">
          <img src={genshin} alt="genshin" />
        </div>
      </div>
    </div>
  );
}

export default NewSectionFlex;
