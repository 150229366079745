import React from 'react'
import { Link } from 'react-router-dom'

const FooterSecondCard = () => {
  return (
    <div className='footer_second_card'>
        <h5>GameSatış</h5>
        <ul>
            <li>
                <Link to={''}>Hakkımızda</Link>
            </li>
            <li>
                <Link to={''}>Hakkımızda</Link>
            </li>
            <li>
                <Link to={''}>Hakkımızda</Link>
            </li>
            <li>
                <Link to={''}>Hakkımızda</Link>
            </li>
            <li>
                <Link to={''}>Hakkımızda</Link>
            </li>
            <li>
                <Link to={''}>Hakkımızda</Link>
            </li>
        </ul>
    </div>
  )
}

export default FooterSecondCard