import { faInbox } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import Input from '../Input'

const BalanceCoupon = () => {
    const [ form, setForm ] = useState(
        {
            username: "",
            couponValue: "",
            couponMessage: ""
        }
    )

    function handleForm(e) {
        setForm((prew) => {
            return {
                ...prew,
                [e.target.name]: e.target.value
            }
        })
    }

    function handleSubmit(e) {
        e.preventDefault();
    }

  return (
    <div className="balanceCoupon">
        <h2> Bakiye Kuponu </h2>

        <div className="profileInfo__infoMessage">
            <div className="profileInfo__infoMessage--icon">
                <FontAwesomeIcon icon={faInbox}/>
            </div>
            <div className="profileInfo__infoMessage--text">
                <h4>
                    Bakiye Kupon Bilgilendirmesi
                </h4>
                <p>
                    Bakiye kuponu ile başka kullanıcılara bakiyenizi kupon şeklinde hediye edebilirsiniz. Oluşturduğunuz kuponları sadece kuponun sahibi kullanabilir kupon 24 saat içerisinde kullanılmaz ise otomatik olarak iptal olur ve bakiyesi hesabınıza aktarılır.
                </p>
            </div>
        </div>

        <form className='balanceCoupon__form' onSubmit={handleSubmit}>

            <label>
                Kullanacak Kullanıcı Adı
                <Input
                    value={form.username}
                    onChange={handleForm}
                    name='username'
                />
            </label>

            <label>
                Kuponun değeri (₺)
                <Input
                    value={form.couponValue}
                    onChange={handleForm}
                    name='couponValue'
                />
            </label>

            <label>
                Kupon Mesajı
                <textarea 
                    name="couponMessage"
                    value={form.couponMessage}
                    onChange={handleForm}
                    placeholder='Bu alana kuponu kullanan kişide gözükmesini istediğiniz mesajınızı notunuzu belirtebilirsiniz.'
                ></textarea>
            </label>

            <button> Kuponu Oluştur </button>
        </form>

        <div className="createdCoupons">
            <h2> Oluşturduğun Kuponlar </h2>    
            <div className="createdCoupons__message">
                <strong> Gösterilecek veri bulunamadı </strong>
                <p>Daha önce hiçbir bakiye kuponu oluşturmamışsınız.</p>
            </div>
        </div> 

    </div>
  )
}

export default BalanceCoupon