import React from 'react'

/*  avatars  */

import avatar1 from "../../images/avatar1.webp"
import avatar2 from "../../images/avatar2.webp"
import avatar3 from "../../images/avatar3.webp"
import avatar4 from "../../images/avatar4.webp"
import avatar5 from "../../images/avatar5.webp"
import avatar6 from "../../images/avatar6.webp"
import avatar7 from "../../images/avatar7.webp"
import avatar8 from "../../images/avatar8.webp"
import avatar9 from "../../images/avatar9.webp"
import avatar10 from "../../images/avatar10.webp"
import avatar11 from "../../images/avatar11.webp"
import avatar12 from "../../images/avatar12.webp"
import avatar13 from "../../images/avatar13.webp"
import avatar14 from "../../images/avatar14.webp"
import avatar15 from "../../images/avatar15.webp"
import avatar16 from "../../images/avatar16.webp"
import avatar17 from "../../images/avatar17.webp"
import avatar18 from "../../images/avatar18.webp"

const Avatar = () => {
  return (
    <div className="avatar">
        <h2> Avatar </h2>
        <div className="avatar__top">
            <div className="avatar__topLeft">
                <p>
                    Aşağıda bulunan varsayılan avatarlardan birini seçebilirsin,
                    veya kendi avatarını yükleyebilirsin.
                </p>
                <input type="file" />
                <p className="avatar__topLeft--message">
                    En fazla 500x500 boyutlarında olmalıdır.
                    Maks. 500KB
                </p>
            </div>
            <div className="avatar__topRight">
                <strong> Mevcut Avatar </strong>
                <div className="avatar__topRight--img">
                    <img src={avatar1} alt="" />
                </div>
            </div>
        </div>

        <div className="avatar__main">

            <div className="avatarBox active">
                <img src={avatar1} alt="" />
            </div>

            <div className="avatarBox">
                <img src={avatar2} alt="" />
            </div>

            <div className="avatarBox">
                <img src={avatar3} alt="" />
            </div>

            <div className="avatarBox">
                <img src={avatar4} alt="" />
            </div>

            <div className="avatarBox">
                <img src={avatar5} alt="" />
            </div>

            <div className="avatarBox">
                <img src={avatar6} alt="" />
            </div>

            <div className="avatarBox">
                <img src={avatar7} alt="" />
            </div>

            <div className="avatarBox">
                <img src={avatar8} alt="" />
            </div>

            <div className="avatarBox">
                <img src={avatar9} alt="" />
            </div>

            <div className="avatarBox">
                <img src={avatar10} alt="" />
            </div>

            <div className="avatarBox">
                <img src={avatar11} alt="" />
            </div>

            <div className="avatarBox">
                <img src={avatar12} alt="" />
            </div>

            <div className="avatarBox">
                <img src={avatar13} alt="" />
            </div>

            <div className="avatarBox">
                <img src={avatar14} alt="" />
            </div>

            <div className="avatarBox">
                <img src={avatar15} alt="" />
            </div>

            <div className="avatarBox">
                <img src={avatar16} alt="" />
            </div>

            <div className="avatarBox">
                <img src={avatar17} alt="" />
            </div>

            <div className="avatarBox">
                <img src={avatar18} alt="" />
            </div>

        </div>

        <button> Güncelle </button>
    </div>
  )
}

export default Avatar