import React from 'react'
import SectionSlider from './SectionSlider'
import { SwiperSlide } from 'swiper/react'
import TrendCategoryCard from './TrendCategoryCard'

// Slider şəkilləri
import image1 from "../images/mlbb-elm-slider.webp";
import image2 from "../images/free-fire-silader.webp";
import image3 from "../images/league-of-slider.webp";
import image4 from "../images/mobile-legends-slide.webp";
import image5 from "../images/pubg-mobile-slider.webp";
import image6 from "../images/roblox-slider.webp";
import image7 from "../images/valorant-slider.webp";
import { IoFlameOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';



const TrendCategorySection = () => {
    const TrendCategoryDatas = [
        {
            image: image1,
            title: "PUBG UC Epin",
            description: "itemsatış.com'dan %30'a varan C...",
        },
        {
            image: image2,
            title: "Genesis Crystals",
            description: "Satın aldığınız Kristal, Genshin imp...",
        },
        {
            image: image3,
            title: "Steam Cüzdan Kodu TR",
            description: "itemsatış güvencesiyle Steam C...",
        },
        {
            image: image4,
            title: "Riot Points RP",
            description: "itemsatış.com indirimli League of...",
        },
        {
            image: image5,
            title: "Zula Altını",
            description: "itemsatış'dan %30 indirimli Zula A...",
        },
        {
            image: image6,
            title: "Zula Altını",
            description: "itemsatış'dan %30 indirimli Zula A...",
        },
        {
            image: image7,
            title: "Zula Altını",
            description: "itemsatış'dan %30 indirimli Zula A...",
        },
        {
            image: image1,
            title: "PUBG UC Epin",
            description: "itemsatış.com'dan %30'a varan C...",
        },
        {
            image: image2,
            title: "Genesis Crystals",
            description: "Satın aldığınız Kristal, Genshin imp...",
        },
        {
            image: image3,
            title: "Steam Cüzdan Kodu TR",
            description: "itemsatış güvencesiyle Steam C...",
        },
        {
            image: image4,
            title: "Riot Points RP",
            description: "itemsatış.com indirimli League of...",
        },
        {
            image: image5,
            title: "Zula Altını",
            description: "itemsatış'dan %30 indirimli Zula A...",
        },
        {
            image: image6,
            title: "Zula Altını",
            description: "itemsatış'dan %30 indirimli Zula A...",
        },
        {
            image: image7,
            title: "Zula Altını",
            description: "itemsatış'dan %30 indirimli Zula A...",
        },
    ];

      

  return (
    <section className="container sectionSlider">
        <div className='main_second_buttons'>
            <button className='main_second_active_button'>
                <IoFlameOutline /> Trend E-Pin Kategorileri
            </button>
        </div>

        <SectionSlider>
            {TrendCategoryDatas.map((data, index) => (
                <SwiperSlide key={index} style={{ width: "100%" }}>
                    <Link to="#">
                        <TrendCategoryCard data={data}/>
                    </Link>
                </SwiperSlide>
            ))}
        </SectionSlider>
    </section>
  )
}

export default TrendCategorySection