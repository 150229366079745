import React, { useState } from 'react'

import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules//react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FaCloudArrowUp, FaRightLeft } from 'react-icons/fa6';
import InputCheck from '../InputCheck';
import Input from '../Input';



const AddAdvertForm = () => {
  const postTypes = [
    {
      id:1,
      value: "manualDelivery",
      title: "Manuel Teslimat"
    },
    {
      id:2,
      value: "autoDelivery",
      title: "Stoklu Ürün (Otomatik Teslimat)"
    },
    {
      id:3,
      value: "purchaseAnnouncement",
      title: "Alım İlanı"
    },
    
  ]

  const [ postType, setPostType ] = useState( postTypes[0]?.value || null )

  function handlePostType(e) {
    setPostType(e.target.value)
  }

  const [ form, setForm ] = useState({
    postType: postTypes[0]?.value || "",
    postTitle: "",
    postDesc: "",
  })
  

  return (
    <section className='addAdvertForm'>

      <div className="addAdvertForm__category">
        <h4 className="addAdvertForm__category--heading">
          Kategori
        </h4>

        <div className="addAdvertForm__category--main">
          <div className="addAdvertForm__category--breadcrumb">
            <span>Instagram Reklam Satın Al</span>
            <span>Reklam Satışı</span>
          </div>
          <button className="addAdvertForm__category--change">
            <FaRightLeft/>
            Kategori Değiştir
          </button>
        </div>
      </div>


      <div className="addAdvertForm__postType">
        <h4>İlan Tipi</h4>

        <div className="addAdvertForm__postType--main">
          {
            postTypes.map((post) => (
              <label className='addAdvertForm__postType--label' key={post.id}>
                <InputCheck
                  radio
                  name="postType"
                  value={post.value}
                  onChange={handlePostType}
                  checked={ postType == post.value }
                />
                {post.title}
              </label>
            ))
          }
        </div>
      </div>


      <div className="addAdvertForm__postTitle">
        <h4> İlan Başlığı </h4>

        <Input
          value={null}
          onChange={null}
          name='postTitle'
        />
      </div>


      <div className="addAdvertForm__desc">
        <h4> Açıklama </h4>

        <div className="addAdvertForm__desc--main">

          <Editor
            editorState={null}
            onEditorStateChange={null}
            toolbarClassName="custom-toolbar"
            editorClassName="custom-editor"
            wrapperClassName="custom-wrapper"
            toolbar={{
              options: ['inline', 'list', 'textAlign', 'colorPicker'],
              inline: {
                options: [ 'bold', 'italic', "underline" ]
              },
              list: {
                options: ['unordered', 'ordered']
              },
              textAlign: {
                options: ['left', 'center', 'right', 'justify'],
                inDropdown: true,
              },
              colorPicker: {
                className: 'color-picker'
              }
            }}
          />

          {/* <div className="addAdvertForm__deliveryTime">
            <h4> Teslimat Süresi </h4>
            

          </div> */}

          <div className="addAdvertForm__prices">
            <div className="addAdvertForm__postPrice">
              <h4> İlan Fiyatı </h4>

              <Input
                value={null}
                onChange={null}
                name='postTitle'
              />
            </div>

            <div className="addAdvertForm__netPrice">
              <h4> Alacağınız Net Ücret </h4>

              <Input
                value={null}
                onChange={null}
                name='postTitle'
              />
            </div>

            <div className="addAdvertForm__commission">
              <h4> Site Komisyonu (% 7 ) </h4>

              <Input
                value={null}
                onChange={null}
                name='postTitle'
              />
            </div>
          </div>

          <div className="addAdvertForm__images">
            <h4> İlan Görselleri </h4>

            <label>
              <div className="addAdvertForm__imagesMain">
                <FaCloudArrowUp/>
                <p> Resimleri buraya sürükleyin veya tıklayarak resim seçin </p>
                <span> Önerilen ilan görseli boyutu : 1110 x 750 px </span>
              </div>

              <input 
                type="file" 
              />
            </label>
          </div>

        </div>
      </div>





      

    </section>
  )
}

export default AddAdvertForm