import React from 'react'
import FooterEndContainer from '../components/FooterEndContainer'
import FooterThirdContainer from '../components/FooterThirdContainer'
import FooterSecondContainer from '../components/FooterSecondContainer'
import FooterFirstContainer from '../components/FooterFirstContainer'
import OtherHeader from '../components/OtherHeader'
import OtherNewsCardsContainer from '../components/OtherNewsCardsContainer'
import OtherHeaderDetail from '../components/OtherHeaderDetail'

const News = ({ otherPageBanner,banners }) => {
    return (
        <div>
            <OtherHeader otherHeaderPagesLink="Blog" otherPageBanner={otherPageBanner} banners={banners}/>
            {/* <OtherHeaderDetail banners={banners} otherHeaderPagesLink="Blog"/> */}
            <main>
                <section>
                    {/* <OtherGamesInput /> */}
                    <h2 className="section_header">Tüm Bloglar</h2>
                    <OtherNewsCardsContainer />
                </section>
            </main>
            <footer>
                <div className="footer_parent_container">
                    <FooterFirstContainer />
                    <FooterSecondContainer />
                    <FooterThirdContainer />
                </div>
                <FooterEndContainer />

            </footer>
        </div>
    )
}

export default News