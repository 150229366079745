import React from 'react'
import LinkedAccountCard from './LinkedAccountCard'

import iconGoogle from "../../images/icon_Google.webp"
import { FaDiscord, FaFacebook, FaServer, FaSteam, FaTelegram } from 'react-icons/fa'

const LinkedAccounts = ({userObj}) => {
  return (
    <div className="linkedAccounts">
        <h2> Oturuma Bağlı Hesaplar </h2>
        <div className="linkedAccounts__list">
            <LinkedAccountCard
                icon={<FaFacebook/>}
                logoBackgroundColor="#3B5998"
                title="Facebook hesabı bağlanmamış"
                buttonText="Hesap bağla"
            />

            <LinkedAccountCard
                img={iconGoogle}
                logoBackgroundColor="#EFEFEF"
                title="Google hesabı bağlanmış"
                desc={`My AnGeLs (${userObj.email})`}   
                buttonText="Bağlantıyı kopart"
                linked = {true}
            />

            <LinkedAccountCard
                icon={<FaSteam/>}
                logoBackgroundColor="#333233"
                title="Steam hesabı bağlanmamış"
                buttonText="Hesap bağla"
            />

            <LinkedAccountCard
                icon={<FaDiscord/>}
                logoBackgroundColor="#5468FF"
                title="Discord hesabı bağlanmamış"
                buttonText="Hesap bağla"
            />

        </div>


        <h2> Entegrasyon Bağlantıları </h2>

        <div className="linkedAccounts__list">

            <LinkedAccountCard
                icon={<FaTelegram/>}
                logoBackgroundColor="#0088CC"
                title="Google hesabı bağlanmış"
                buttonText=" Bildirimleri Kapat"
                linked={true}
            />

            <LinkedAccountCard
                icon={<FaServer/>}
                logoBackgroundColor="#0088CC"
                title="Steam hesabı bağlanmamış"
                buttonText=" Bildirimleri Aç"
            />

            <LinkedAccountCard
                icon={<FaDiscord/>}
                logoBackgroundColor="#0088CC"
                title="Discord hesabı bağlanmamış"
                buttonText=" Bildirimleri Aç"
            />

        </div>

    </div>
  )
}

export default LinkedAccounts