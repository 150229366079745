import React, { useEffect } from 'react'
import ShopProfileSectionFirstHeader from './ShopProfileSectionFirstHeader'
import ShopProfileSectionFirstMain from './ShopProfileSectionFirstMain'
import { useDispatch, useSelector } from 'react-redux'
import { getShopProductEl } from '../actions/MainAction'

const ShopProfileSectionFirst = ({id}) => {

    const dispatch=useDispatch()

    const {shopProducts}=useSelector(state=>state.Data)
    console.log(shopProducts);

    useEffect(()=>{
       dispatch(getShopProductEl(id)) 
    },[dispatch])
    return (
        <div className="shop_profile_section_first container">
            <ShopProfileSectionFirstHeader shopProducts={shopProducts}/>
            <ShopProfileSectionFirstMain shopProducts={shopProducts}/>
        </div>
    )
}

export default ShopProfileSectionFirst