import React from 'react'
import { IoFlameOutline } from "react-icons/io5";


const DealsDaysButtons = () => {
    return (
        <div className='main_second_buttons container'>
            <button
                className='main_second_active_button'
                
            >
                <IoFlameOutline /> Günün Fırsatları
            </button>

            
        </div>
    )
}

export default DealsDaysButtons