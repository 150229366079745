import React from 'react'

const FooterContactBox = ({data}) => {
  return (
    <div className='footer__contact--box'>
        <span> {data.title} </span>
        <h5> {data.heading} </h5>
        <p> {data.desc} </p>
    </div>
  )
}

export default FooterContactBox