import React, { useEffect, useState } from 'react'
import { FaBalanceScale, FaBoxOpen, FaTrophy, FaUsers } from 'react-icons/fa';
import ShopProfileAnnounceFilterContainer from './ShopProfileAnnounceFilterContainer';
import ShopProfileFilterCardsContainer from './ShopProfileFilterCardsContainer';

const ShopProfileSectionFirstMain = ({shopProducts}) => {
    const [activeButton, setActiveButton] = useState('');

    // Use useEffect to load the active button from localStorage on component mount
    useEffect(() => {
        const savedActiveButton = localStorage.getItem('activeButton') || 'İlanlar';
        setActiveButton(savedActiveButton);
    }, []);

    // Handler function to change the active button and save it to localStorage
    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
        localStorage.setItem('activeButton', buttonName);
    }
    return (
        <div className="shop_profile_section_first_main">
            <div className='main_second_buttons container'>
                <button
                    className={activeButton === 'İlanlar' ? 'main_second_active_button' : ''}
                    onClick={() => handleButtonClick('İlanlar')}
                >
                    <FaBoxOpen /> İlanlar
                </button>

                <button
                    className={activeButton === 'Değerlendirmeler' ? 'main_second_active_button' : ''}
                    onClick={() => handleButtonClick('Değerlendirmeler')}
                >
                    <FaBalanceScale />  Değerlendirmeler
                </button>

                <button
                    className={activeButton === 'Başarımlar' ? 'main_second_active_button' : ''}
                    onClick={() => handleButtonClick('Başarımlar')}
                >
                    <FaTrophy /> Başarımlar
                </button>

                <button
                    className={activeButton === 'Takipçiler' ? 'main_second_active_button' : ''}
                    onClick={() => handleButtonClick('Takipçiler')}
                >
                    <FaUsers /> Takipçiler
                </button>
            </div>
            <div className='shop_profile_section_first_main_filter'>
                <div className="player_market_section_first_container container">
                    <ShopProfileAnnounceFilterContainer />
                    <ShopProfileFilterCardsContainer shopProducts={shopProducts}/>
                </div>
            </div>

        </div>
    )
}

export default ShopProfileSectionFirstMain