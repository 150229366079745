import React, { useState } from 'react'
import AddAdvertPackageCard from './AddAdvertPackageCard'

/*  Images  */

import cardImg1 from "../../images/startup.svg"
import cardImg2 from "../../images/popularity.svg"
import cardImg3 from "../../images/qwsQ8EhnAaNFjGxI4TSC9J7p2.webp"
import cardImg4 from "../../images/showcase.svg"


const AddAdvertPackage = () => {
  const datas=[
    {
      id: 1,
      title: "Anasayfa Vitrin ilanı",
      desc: "Vitrin İlanlar Ana Sayfa ve İlan Kategorisinde en üstte sabitlenmiş olarak görünmektedir. Vitrin İlan dopingi sayesinde ilanınız normal ilanlara göre %85 daha fazla görüntülenme almaktadır ve daha hızlı satılmaktadır.",
      image: cardImg1,
      ribbon: "Tercih Edilen",
      options: [
        {
          id:1,
          title: "Vitrin ilanı istemiyorum",
        },
        {
          id:2,
          title: "12 saat (8.00₺)",
        },
        {
          id:3,
          title: "1 gün (14.00₺)",
        },
        {
          id:4,
          title: "2 gün (25.00₺)",
        },
        {
          id:5,
          title: "3 gün (40.00₺)",
        },
        {
          id:6,
          title: "7 gün (75.00₺)",
        },
        {
          id:7,
          title: "30 gün (180.00₺)",
        }
      ]
    },

    {
      id: 2,
      title: "İlanını öne çıkar",
      desc: "Öne çıkarılan ilanlar diğer ilanlardan daha fazla ilgi çekmektedir ve %80 oranında diğer ilanlardan daha hızlı satılmaktadır. İlanınızı öne çıkarttığınızda ilan listeleme sayfalarında organik sıralarınızda bulunacaksınız fakat diğer ilanlardan daha belirgin olmanız için başlık kalınlığı arkaplan rengi vs. gibi bazı özel stiller uygulanacaktır bu sebeple diğer ilanlardan daha belirgin olacaktır.",
      image: cardImg2,
      ribbon: "",
      options: [
        {
          id:1,
          title: "Öne çıkartmak istemiyorum",
        },
        {
          id:2,
          title: "12 saat (5.00₺)",
        },
        {
          id:3,
          title: "1 gün (10.00₺)",
        },
        {
          id:4,
          title: "2 gün (16.00₺)",
        },
        {
          id:5,
          title: "3 gün (27.00₺)",
        },
        {
          id:6,
          title: "7 gün (49.00₺)",
        },
        {
          id:7,
          title: "30 gün (120.00₺)",
        }
      ]
    },

    {
      id: 3,
      title: "Sohbet & Bildirim Vitrin İlanı",
      desc: "İtemSatış’ın ana sayfasından sonra en aktif sayfalarının mesajlar ve bildirimler sayfası olduğunu biliyor muydunuz? Sohbet & Bildirim vitrini sayesinde ilanınız mesajlarım ve bildirimlerim sayfasının altında listelenecek ve %75’e yakın oranda daha fazla görüntülenme alacaktır. Bu sayede satış sayınız artabilir.",
      image: cardImg3,
      ribbon: "Yeni Doping %25 İndirim",
      options: [
        {
          id:1,
          title: "Vitrin ilanı istemiyorum",
        },
        {
          id:2,
          title: "12 saat (6.00₺)",
        },
        {
          id:3,
          title: "1 gün (10.00₺)",
        },
        {
          id:4,
          title: "2 gün (18.00₺)",
        },
        {
          id:5,
          title: "3 gün (28.00₺)",
        },
        {
          id:6,
          title: "7 gün (50.00₺)",
        },
        {
          id:7,
          title: "30 gün (130.00₺)",
        }
      ]
    },

    {
      id: 4,
      title: "Pazar Vitrini",
      desc: "Bu dopingi satın alarak ilanınızı 'İlan Pazarı' sayfasında en üstte sergileyebilirsiniz. Pazar vitrini sayesinde ilanınız, normal ilanlara göre %70 daha fazla görüntülenme alacaktır. Bu sayede de satış hızınız ve sayınız artacak.",
      image: cardImg4,
      ribbon: "",
      options: [
        {
          id:1,
          title: "Vitrin ilanı istemiyorum",
        },
        {
          id:2,
          title: "12 saat (4.00₺)",
        },
        {
          id:3,
          title: "1 gün (7.00₺)",
        },
        {
          id:4,
          title: "2 gün (12.00₺)",
        },
        {
          id:5,
          title: "3 gün (20.00₺)",
        },
        {
          id:6,
          title: "7 gün (36.00₺)",
        },
        {
          id:7,
          title: "30 gün (90.00₺)",
        }
      ]
    }

  ]
  

  const [ checkedOptions, setCheckedOptions ] = useState(
    [...datas].reduce( ( obj, data ) => {
      return {
        ...obj,
        [ data.id ] : data.options[0].id
      }
    }, {})
  );

  function handleCheckedOptions(cardID, optionID) {
    setCheckedOptions( prew => {
      return {
        ...prew,
        [cardID] : optionID
      }
    } )
  }  

  const rotationValues = [0, 180, 300, 40];

  return (
    <section className='addAdvertPackage'>
        { datas?.map( (item, ind) => (
          <AddAdvertPackageCard
            key={item.id} 
            data={item} 
            checkedOptID={ checkedOptions[item.id] } 
            onChange={handleCheckedOptions} 
            hueRotate={ rotationValues[ind%4] }
          />
        ))}
    </section>
  )
}

export default AddAdvertPackage