import React from 'react'
import { Link } from 'react-router-dom'
import { FaInstagram } from "react-icons/fa";
import DiscordImg from "../images/widget.png"


const FooterThirdRightCard = () => {
    return (
        <div className='footer_third_right_card'>
            <h6>Bizi Takip Et</h6>
            <Link className='discord_link' to="https://discord.com/invite/UtjXNfpdG9" target="_blank" rel="noopener noreferrer">
                <img src={DiscordImg} alt="" />
            </Link>
            <ul>
                <li>
                    <Link to={''}><FaInstagram /></Link>
                </li>
                <li>
                    <Link to={''}><FaInstagram /></Link>
                </li>
                <li>
                    <Link to={''}><FaInstagram /></Link>
                </li>
                <li>
                    <Link to={''}><FaInstagram /></Link>
                </li>
                <li>
                    <Link to={''}><FaInstagram /></Link>
                </li>
                <li>
                    <Link to={''}><FaInstagram /></Link>
                </li>
            </ul>
           
        </div>
    )
}

export default FooterThirdRightCard