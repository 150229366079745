import { faArrowDownWideShort, faClockRotateLeft, faMoneyCheckDollar, faUserXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'

const GiveawaySection = (props) => {
    const sorts = [
        {
            id: 1,
            title: "Önerilen Sıralama",
            icon: faArrowDownWideShort
        },
        {
            id: 2,
            title: "Katılmadıklarım En Üstte",
            icon: faUserXmark
        },
        {
            id: 3,
            title: "Tutara Göre Sıralama",
            icon: faMoneyCheckDollar
        },
        {
            id: 4,
            title: "Bitişe Göre Sıralama",
            icon: faClockRotateLeft
        },
        
    ]

    const [ selectedSort, setSelectedSort ] = useState(1);
    const [sortMenu, setSortMenu] = useState(false);
    const dropdownRef = useRef(null);

    function handleChangeSort(id) {
        setSortMenu(false);
        setSelectedSort(id);
    }   
    
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setSortMenu(false);
        }
    };

    useEffect(() => {
        if (sortMenu) {
          document.addEventListener('mousedown', handleClickOutside);
        } else {
          document.removeEventListener('mousedown', handleClickOutside);
        }
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [sortMenu]);

  return (
    <section className="giveawaySection">
        <div className="container">
            <div className="giveawaySection__heading">
                <h3>
                    {props.heading}
                </h3>
                {
                    props.sort &&
                    <div className="giveawaySection__sort" ref={dropdownRef}>
                        <button onClick={() => setSortMenu(!sortMenu)} className={sortMenu ? "active" : ""}>
                            <FontAwesomeIcon icon={sorts && sorts.filter(i => i.id == selectedSort)[0]?.icon}/>
                            {
                                sorts && sorts.filter(i => i.id == selectedSort)[0]?.title
                            }
                        </button>
                        <ul className={`giveawaySection__sort--dropdown ${sortMenu ? "active" : ""}`}>
                            {
                                sorts.map(item => (
                                    <li key={item?.id}>
                                        <button onClick={() => handleChangeSort(item?.id)} className={item?.id == selectedSort ? "active" : ""}>
                                            <FontAwesomeIcon icon={item?.icon}/>
                                            {item?.title}  
                                        </button>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                }
            </div>
            <div className="giveawaySection__main">
                {props.children}
            </div>
            {
                props.loadMore &&
                <div className="giveawayLoadMoreBtn">
                    <button>
                        Daha fazla tamamlanmış çekiliş göster
                    </button>
                </div>
            }
        </div>
    </section>
  )
}

export default GiveawaySection