import React from 'react'
import SectionSlider from './SectionSlider'
import { SwiperSlide } from 'swiper/react'
import TrendCategoryCard from './TrendCategoryCard'

// Slider şəkilləri
import image1 from "../images/mlbb-elm-slider.webp";
import image2 from "../images/free-fire-silader.webp";
import image3 from "../images/league-of-slider.webp";
import image4 from "../images/mobile-legends-slide.webp";
import image5 from "../images/pubg-mobile-slider.webp";
import image6 from "../images/roblox-slider.webp";
import image7 from "../images/valorant-slider.webp";
import { IoFlameOutline } from 'react-icons/io5';
import DealsDaysCard from './DealsDaysCard';
import { Link } from 'react-router-dom';



const BestSellingProductsSection = ({datas}) => {
      

  return (
    <section className="container sectionSlider">
        <div className='main_second_buttons'>
            <button className='main_second_active_button'>
                <IoFlameOutline /> Çok Satılan E-Pin Ürünleri
            </button>
        </div>

        {      /* datas prop-u ile datalari array olarag ötür */      }

        {/* <SectionSlider>
            {datas && datas.map((data, index) => (         
                <SwiperSlide key={index} style={{ width: "100%" }}>
                    <Link to="#">
                        <DealsDaysCard withoutBtn data={null}/>
                    </Link>
                </SwiperSlide>
            ))}
        </SectionSlider> */}

        

        { /*   asagidaki test merhelesi ucundur   */}

        <SectionSlider>
            <SwiperSlide style={{ width: "100%" }}>
                <DealsDaysCard withoutBtn/>
            </SwiperSlide>
            <SwiperSlide style={{ width: "100%" }}>
                <DealsDaysCard withoutBtn/>
            </SwiperSlide>
            <SwiperSlide style={{ width: "100%" }}>
                <DealsDaysCard withoutBtn/>
            </SwiperSlide>
            <SwiperSlide style={{ width: "100%" }}>
                <DealsDaysCard withoutBtn/>
            </SwiperSlide>
            <SwiperSlide style={{ width: "100%" }}>
                <DealsDaysCard withoutBtn/>
            </SwiperSlide>
            <SwiperSlide style={{ width: "100%" }}>
                <DealsDaysCard withoutBtn/>
            </SwiperSlide>
            <SwiperSlide style={{ width: "100%" }}>
                <DealsDaysCard withoutBtn/>
            </SwiperSlide>
            <SwiperSlide style={{ width: "100%" }}>
                <DealsDaysCard withoutBtn/>
            </SwiperSlide>
            <SwiperSlide style={{ width: "100%" }}>
                <DealsDaysCard withoutBtn/>
            </SwiperSlide>  
        </SectionSlider>

    </section>
  )
}

export default BestSellingProductsSection