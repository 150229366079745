import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MainSecondCard from './MainSecondCard';
import { useDispatch, useSelector } from 'react-redux';
import { getHighlightProducts, getPopularList } from '../actions/MainAction';

const MainFoutrhContainer = ({activeFourthButton}) => {
    const dispatch = useDispatch();
    const { highlightProducts, popularProducts } = useSelector(state => state.Data);
    let langText = localStorage.getItem("selectedLanguage");

    useEffect(() => {
        dispatch(getHighlightProducts(langText));
        dispatch(getPopularList(langText));
    }, [dispatch, langText]);

    const formatUrl = (title, id) => {
        const formattedTitle = title
            .replace(/\//g, '-')        
            .replace(/\?/g, '')         
            .trim()                     
            .replace(/\s+/g, '')        
            .toLowerCase();             

        return `/${formattedTitle}/${id}`;
    };

    const renderProducts = () => {
        let products = [];
        if (activeFourthButton === 'Valorant') {
            products = popularProducts;
        } else if (activeFourthButton === 'Vitrin İlanları') {
            products = highlightProducts;
        } else if (activeFourthButton === 'Yeni İlanlar') {
            // yeni ilanlar arrayi burada olmalıdır
        } else if (activeFourthButton === 'Favori İlanlar') {
            // favori ilanlar arrayi burada olmalıdır
        }

        return products?.map((data, i) => (
            <Link key={i} to={formatUrl(data.title, data.id)}>
                <MainSecondCard data={data} />
            </Link>
        ));
    };

  return (
    <div className='main_second_container container'>
    {renderProducts()}
</div>
  )
}

export default MainFoutrhContainer