import React from 'react'
import footerPaymentImg from "../images/odemeyontemleri.webp"

const FooterPayment = () => {
  return (
    <div className='footer__payment container'>
        <div className="footer__payment--img">
            <img src={footerPaymentImg} alt="payment" />
        </div>
    </div>
  )
}

export default FooterPayment