import React, { useEffect, useState } from 'react'
import { FaBalanceScale, FaBoxOpen, FaTrophy, FaUsers } from 'react-icons/fa';
import logoImg1 from "../images/searchlogo.webp"
import logoImg2 from "../images/medal.webp"
import logoImg3 from "../images/follow.webp"


const ProfileDetailMainSecond = () => {
    const [activeButton, setActiveButton] = useState('');

    // Use useEffect to load the active button from localStorage on component mount
    useEffect(() => {
        const savedActiveButton = localStorage.getItem('activeButton') || 'İlanlar';
        setActiveButton(savedActiveButton);
    }, []);

    // Handler function to change the active button and save it to localStorage
    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
        localStorage.setItem('activeButton', buttonName);
    }
    return (
        <div className="shop_profile_section_first_main">
            <div className='main_second_buttons container'>
                <button
                    className={activeButton === 'İlanlar' ? 'main_second_active_button' : ''}
                    onClick={() => handleButtonClick('İlanlar')}
                >
                    <FaBoxOpen /> İlanlar
                </button>

                <button
                    className={activeButton === 'Değerlendirmeler' ? 'main_second_active_button' : ''}
                    onClick={() => handleButtonClick('Değerlendirmeler')}
                >
                    <FaBalanceScale />  Değerlendirmeler
                </button>

                <button
                    className={activeButton === 'Başarımlar' ? 'main_second_active_button' : ''}
                    onClick={() => handleButtonClick('Başarımlar')}
                >
                    <FaTrophy /> Başarımlar
                </button>

                <button
                    className={activeButton === 'Takipçiler' ? 'main_second_active_button' : ''}
                    onClick={() => handleButtonClick('Takipçiler')}
                >
                    <FaUsers /> Takipçiler
                </button>
                <button
                    className={activeButton === 'Takip Ettikleri' ? 'main_second_active_button' : ''}
                    onClick={() => handleButtonClick('Takip Ettikleri')}
                >
                    <FaUsers />  Takip Ettikleri
                </button>
            </div>

            <div className="profileDetailMain">
                {
                    activeButton == "İlanlar" ?

                    <div className="profileDetailMain__emptySection">
                        <div className="profileDetailMain__emptySection--img">
                            <img src={logoImg1} alt="" />
                        </div>
                        <h4>
                            Aktif ilan bulunamadı.
                        </h4>
                        <p>
                            Satıcıya ait hiçbir aktif ilan bulunamadı.
                        </p>
                    </div>

                    : activeButton == "Değerlendirmeler" ?

                    <div className="profileDetailMain__emptySection">
                        <div className="profileDetailMain__emptySection--img">
                            <img src={logoImg2} alt="" />
                        </div>
                        <h4>
                            Satıcı değerlendirmesi yok!
                        </h4>
                        <p>
                            Bu satıcının henüz bir satış tecrübesi bulunmuyor, Satıcı değerlendirmesi satış işlemi yapıldıktan sonra alıcının satıcıyı değerlendirdiği bir platformdur.
                        </p>
                        <a href="#">
                            Satıcı değerlendirmesi nedir ?
                        </a>
                    </div>

                    : activeButton == "Başarımlar" ?

                    <div className="profileDetailMain__emptySection">
                        <div className="profileDetailMain__emptySection--img">
                            <img src={logoImg3} alt="" />
                        </div>
                        <h4>
                            Başarım bulunamadı.
                        </h4>
                        <p>
                            Bu kullanıcının hiçbir başarımı mevcut değil.
                        </p>
                    </div>

                    : activeButton == "Takipçiler" ?

                    <div className="profileDetailMain__emptySection">
                        <div className="profileDetailMain__emptySection--img">
                            <img src={logoImg2} alt="" />
                        </div>
                        <h4>
                            Hiç takipçisi yok.
                        </h4>
                        <p>
                            Bu kullanıcı çok yalnız onu takip etmek ister misin?
                        </p>
                        <p>
                            Yukarıda ki "Takip Et" butonuna tıklayarak bu kullanıcıyı takip edebilirsiniz.
                        </p>
                    </div>

                    : activeButton == "Takip Ettikleri" &&

                    <div className="profileDetailMain__emptySection">
                        <div className="profileDetailMain__emptySection--img">
                            <img src={logoImg3} alt="" />
                        </div>
                        <h4>
                            Hiç kimseyi takip etmiyorsunuz.
                        </h4>
                        <p>
                            Çok yalnız kalmışsınız.
                        </p>
                        <p>
                            Diğer kullanıcılara takip ederek onlardan bildirim alabilirsiniz.
                        </p>
                    </div>

                }
            </div>

            <div className='shop_profile_section_first_main_filter'>
                <div className="player_market_section_first_container container">
                    {/* <ShopProfileAnnounceFilterContainer />
                    <ShopProfileFilterCardsContainer shopProducts={shopProducts} /> */}
                </div>
            </div>

        </div>
    )
}

export default ProfileDetailMainSecond