import React, { useState } from 'react'
import InputCheck from '../InputCheck'

const AddAdvertRules = () => {
  const [ rulesAgree, setRulesAgree ] = useState(false)

  return (
    <section className='addAdvertRules'>
        <div className="addAdvertRules__text">
          <p>
          İTEMSATIŞ İLAN VERME KURALLARI

 

Aşağıda yazan ilan verme kuralları bireysel ve kurumsal üyeler dahil ve tüm kategori tipleri için geçerlidir. İlan girişinde bulunan Üye, ilan verme kurallarına, üyelik ve kullanıcı sözleşmeleri ile bu belgenin devamında belirtilen Yasak Ürünler Listesi’nde belirtilen kurallara uymayı kabul ve taahhüt etmiş sayılır.

 

1. TANIMLAR

 

İNTERNET SİTESİ : Tüm hakları Şirket’e ait olan, www.itemsatis.com alan adını ve bu alan adına bağlı alt alan adlarından oluşan internet sitesidir.

 

MOBİL UYGULAMA : Tüm hakları Şirket’e ait olan, cep telefonu, tablet ve benzeri taşınabilir cihazlarda çalışan İtemsatış adlı yazılımdır. Bu yazılım Apple App Store veya Google Play gibi mağazalardan elde edilir. Bu mağazalardaki İtemsatış adlı ve ALPHAPINS BİLİŞİM HİZMETLERİ LTD adına yüklü yazılım, gerekli güvenlik şartlarını sağlamaktadır. Bunun haricinde başka bir yolla edilen yazılımın cihazlara kurulmasından kaynaklı güvenlik dair her türlü hatadan Şirket sorumlu değildir.

 

İTEMSATIŞ : İnternet Sitesi ve Mobil Uygulama ile birlikte “İtemsatış” olarak anılmaktadır.

 

ÜYE : İtemsatış üzerinde, oluşturduğu ve onaylanan kullanıcı hesabı ile; listelediği ürünler ve/veya hizmetler ile içeriklerini kendileri oluşturarak Ürünü satışa sunan ve Üyelik Sözleşmesi’nde belirtilen koşullar dâhilinde İtemsatış’a üye olmuş ve İtemsatış’ın sunduğu diğer hizmetlerden faydalanan gerçek veya tüzel kişileri ifade eder. Üyelik için gerekli şartlar Üyelik Sözleşmesinde açıklanmıştır. İtemsatış’a üyelik tipi bireysel veya kurumsal olabilir.

 

SATICI: İtemsatış’a bireysel veya kurumsal üye olarak kendisine ait Ürün’ü Alıcıya satan gerçek veya tüzel kişidir. Satıcı olmak için İtemsatış’a üyelik şarttır. İşbu sözleşme gereği KULLANICI 18 yaşından büyük ve T.C ilgili kanunlarına göre reşid sayıldığını kabul eder. Gerekli üyelik şartları Üyelik Sözleşmesi’nde açıklanmıştır.

 

ALICI: İtemsatış’a bireysel veya kurumsal üye olarak, İtemsatış’ın veya Satıcı’nın ürününü alan gerçek veya tüzel kişidir. Alıcı olabilmek için İtemsatış’a üyelik şarttır. İşbu sözleşme gereği KULLANICI 18 yaşından büyük ve T.C ilgili kanunlarına göre reşid sayıldığını kabul eder. Gerekli üyelik şartları Üyelik Sözleşmesi’nde açıklanmıştır.

 

ÜRÜN : İtemsatış üzerinden, İtemsatış’ın bayilik anlaşması olan 3. taraf firmalardan sağlanan ürünleri, Satıcılar tarafından başta Yasaklı Ürünler listesi olmak üzere; işbu sözleşme, Kullanım Koşulları, Üyelik Sözleşmesi, Mesafeli Satış Sözleşmesi ve diğer eklere uygun olarak satışa sunulan ürün ve hizmetleri ifade eder. Bu ürünler fiziki değildir. Anında tüketilen sayısal bir kod ve oyun parasından oluşan ve genel adı itibariyle E-PIN / Elektronik Pin’dir. Bundan sonra Ürün, E-PIN olarak da anılabilecektir.

 

VİTRİN ve DOPİNG: İlgili ilanın daha fazla kullanıcıya ulaşabilmesi için, ilave bir ücret ile yalnız ilgili ilan için satın alınan, ilan öne çıkarma hizmetidir.

  

2. GENEL HÜKÜMLER

 

2.1. İtemsatış ve bu kapsamda Şirket, 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve 6563 sayılı Elektronik Ticaret Düzenlenmesi Hakkında Kanun kapsamında Satıcı ve Alıcı arasında HİZMETLER tanımının c maddesinde belirtilen alt hizmet için mesafeli sözleşme kurulmasına aracılık eden ve “Aracı Hizmet Sağlayıcı” sıfatını haizdir. İtemsatış’ın satıcıların ürünleri hakkında hiçbir sorumluluğu bulunmamaktadır.

 

2.2. İtemsatış’a üye olmayanlar ilan veremez.

 

2.3. İtemsatış’ta ilan vermek ücretsizdir. Fakat ilgili ilan için kullanılacak Vitrin ve Doping hizmetleri ilave ücrete tabidir.

 

2.4. Satıcı, ilan vermeden önce işbu İlan Verme Kurallarını ve içerisinde bulunan Yasaklı Ürünler Listesini okuduğunu ve anladığını, oluşturmak istediği ilanın işbu kurallara aykırı olmadığını peşine kabul ve beyan eder.

 

2.5. Satıcı, ilanının hiçbir şekilde fikri mülkiyet haklarını ihlal etmediğini, ilanını verdiği ürünün kendisine ait olduğunu, ilan içeriğinin Yasaklı Ürünler Listesi’nde yer almadığını, genel ahlak kurallarına aykırı olmadığını, ilanı verilen ürünün satışa hazır olduğunu, ilan için kullanılan içeriğin satılmak istenen ürünle ilgili olduğunu, ürünün çalışır durumda ve sürekli güncel olduğunu peşinen kabul ve beyan eder.

 

2.6. İlan ile ilgili tüm sorumluluk Satıcı’ya ait olup, İtemsatış hiçbir şekilde sorumluluk kabul etmemektedir. İtemsatış, ilanda yer alan ürün için garanti vermemektedir.

 

2.7. İlan içeriği gerekli görüldüğü durumlarda, Satıcı’ya haber verilmeden İtemsatış tarafından değiştirilebilir.

 

2.8. Piyasa fiyatlarının çok altında veya çok üstünde olan ilanlar, piyasa rekabetini koruma ve haksız kazanç edinimini önleme adına İtemsatış tarafından yayından kaldırılabilir, yayını belirsiz süre durdurulabilir. Satıcı’dan ilanı düzeltmesi istenebilir.

 

2.9. İtemsatış ilanı yayınlamak için Satıcı’ya ait kimlik fotokopisi, yerleşim yeri belgesi, vergi levhası gibi ilave bilgiler isteyebilir.

 

2.10. Aynı ürüne ait ikinci bir ilan girilemez. Bu tür ilanlar mükerrer sayılır ve sistemden Satıcı’ya bilgi verilmeden kaldırılır. Satıcı, mükerrer ilanın yayından kaldırılmasından dolayı hak kaybına uğradığını iddia edemez. Mükerrer ilanlar için Satıcı tarafından öncesinde yapılan ödemelerin iadesi yapılmaz.

 

2.11. İlan içeriğinde Alıcı’yı başka bir platforma yöneltmek veya başka bir firma veya ürünün reklamını yapmak için; başka bir web adresi veya başka bir platformda yer alan ürün linki eklemek yasaktır. Satıcı, ilan içerisinde kendisine ait cep telefonu, e-mail adresi ve bununla sınırlı kalmayacak şekilde kişisel iletişim bilgisi paylaşamaz.

 

2.12. İtemsatış’ta yer alan ilave ilan hizmetlerinin fiyat bilgisi Türk Lirası’dır. Yabancı para biriminde ilan girişi yapılamaz. Fiyatlara KDV dahildir.

 

2.13. İlan için ilave ücret ile kullanılan Vitrin ve Doping hizmetleri yalnız ilgili ilan için geçerlidir. Başka bir ilan için kullanılamaz.

 

2.14. İlanın yayında kalma süresi dolmadan ilanda yer alan ürün satılırsa, ilgili ilandan kalan yayın süresi, Vitrin ve Doping hizmeti başka bir ilan için kullanılamaz veya devredilemez.

 

2.15. İlan için ödenen Vitrin ve Doping ücretleri, ilgili ilan İtemsatış üzerinde yayına başladıktan sonra hiçbir şekilde geri iade edilmez.

 

2.16. Vitrin ve Doping hizmetleri ücretleri www.itemsatis.com adresinde yayınlanmış olup, Satıcı bu fiyatları bildiğini peşinen kabul ve beyan eder. İtemsatış ilan hizmetleri hakkındaki ücretlendirmeyi dilediği zaman ve herhangi bir bildirimde bulunmadan değiştirebilir. Değişiklikler www.itemsatis.com adresinde yayınlandığı anda yürürlüğe girmiş kabul edilir.

 

2.17. İlanlarda; reklam, haksız rekabet, terör, kara para aklama, cumhuriyet, devlet, din, ırkçılık, uyuşturucu, politikacılar ya da diğer tüzel hakkında yazışmalar, pornografik içerik, argo kelimelerin kullanımı veya diğer oyuncuları tacize yönelik davranışlar içeren yahut Atatürk’ün manevi hatırasına hakaret teşkil eden kullanımlar dahil olmak fakat bunlarla sınırlı olmamak üzere mer’i mevzuat kapsamında suç teşkil eden başkaca her türlü işlem, eylem ve tasarruflar yasak ve işbu İlan Verme Kurallarına aykırıdır.

 

2.18. İşbu ilan kurallarına ve Yasaklı Ürünler Listesi’ne aykırı olduğu tespit edilen ilanlar İtemsatış tarafından Satıcı’ya haber verilmeden yayından kaldırılabilir, belirli veya belirsiz süre yayını durdurulabilir. Bu durumda Satıcı, ilanı oluşturduktan sonra ödediği ilave Vitrin ve Doping ücretlerini hiçbir şekilde iade edilmesini talep edemeyeceğini peşinen kabul ve beyan eder.

 

2.19. İlanı yayından kaldırılan veya durdurulan Satıcı’ya gerekli açıklama, Satıcı talep ederse İtemsatış tarafından 48 saat içerisinde yapılır. İlan ile ilgili tüm itirazlar Satıcı tarafından Destek Sistemi üzerinden talep oluşturularak başlatılır. Destek Sistemi haricinde başlatılan itirazlar hiçbir şekilde işleme alınmaz.

 

2.20. Alıcı ve Satıcı arasındaki işlemlerin kayıt altına alınabilmesi ve gerektiğinde adli makamlara delil olarak sunulabilmesi için Alıcı ve Satıcı, aralarındaki anlık mesajlaşma dahil ve bununla da sınırlı kalmayacak tüm işlemlerini İtemsatış üzerinde yapacaklarını peşinen kabul ve beyan eder. Alıcı ve Satıcı’nın, İtemsatış haricinde kendi aralarında yapacakları ticaretten İtemsatış sorumlu tutulamaz ve İtemsatış’tan delil talebinde bulunulamaz.

 

2.21. Satıcı, ilgili ilanda yer alan ürün satışından elde edilen gelirden; İtemsatış’ın internet sitesi veya mobil uygulaması ve bununla sınırlı kalmayacak şekilde farklı alanlarda belirttiği; Hizmet Bedeli, banka transfer ve bununla sınırlı kalmayacak şekilde oluşabilecek ilave ücretlendirmelerin düşülerek geri kalan tutarın kendi hesabına, bakiye olarak yükleneceğini kabul ve beyan eder.

 

2.22. Satıcı’nın ürünü teslim etme süresi ilan oluştururken belirttiği süreyi geçemez. Zamanında teslim edilmeyen Satıcı ilanı yayından kaldırılır. Satıcı’ya olumsuz ticaret puanı verilir. Satıcı tarafından teslimatı yapılmamış ürün satış işlemlerinde, Alıcı bakiyesinden yapılan ürün bedeli kesintisi Alıcı’ya iade edilir. Satıcı yayından kaldırılan ilanını tekrar yayına alabilir fakat bu ilan için alınan öne çıkarma hizmet süresine, yayında olmadığı süre ilave edilmez.

 

2.23. Satıcı aynı ürünü birden fazla alıcıya satamaz. Tespiti durumunda satıcının hesabı bildirimsiz olarak kapatılabilir.

 

3. YASAKLI ÜRÜNLER LİSTESİ

 

Sağlık Beyanlı Ürünler ve Cihazlar

Kültür ve Tabiat Varlıkları

Ateşli Silah ve Kesici Alet Satışı

Yanıcı ve Patlayıcı Malzemeler

Bandrolsüz Ürün Satışı

Alkollü İçecek ve Tütün Mamülleri

Cinsel İçerik ve Pornografik Ürünler

Taklit Ürün Satışı

Resmi Belge Satışı

Trafik Aygıtları ve Plaka

Fon ve Hisse Senedi

Organ

Evcil Hayvan ve Yasalarca Yasaklanmış Hayvanlar

Şans Oyunu Biletleri ve Makineleri

Tarım İlaçları ve İlaçlama Makineleri

Kişilik Hakları İhlaline Sebep Olabilecekler

Telsiz Ürünleri

Etkinlik Biletleri

İlaç Satışı

Ortak veya Hissedar Arama

Siyasi İçerikli Ürünler

Resmi Kıyafet & Askeri Malzemeler

Her Türlü Uyuşturucu Madde

Hack Yazılımları

Sosyal Medya Pazarlama Hizmetleri

SEO vb. Hizmetler

Her Türlü Gıda

Sanal Para Satışı

Kayıt Dışı yada IMEI numarası kopyalanmış Telefonlar

Çalıntı Ürünler

Emlak ve Arsa Satışı

Her Türlü Vasıta Satışı

Kozmetik ve Sağlık Ürünleri

Film izleme platformlarının ürünleri

İç Giyim

Her Türlü Pornografik veya Genel Ahlak Kurallarına Aykırı Ürünler

Her Türlü İş İlanı, Eleman İlanı

Her Türlü Ön Sipariş İlanları

Fiziksel, sanal sunucu (VDS) ilanları

Riot Points, Valorant Points ve diğer tüm E-Pin ilanları

Hile ve indirilebilir 3. parti yazılım ilanları

IPTV, BeinConnect, Digitürk gibi lisanslı ürünlerin kaçak satışı

BluTV, ve Exxen hesap ilanları

Yemeksepeti, getir gibi platformların hesapları

Mining (madencilik) ilanları

Discord Nitro Generator İlanları

Twitch bit v.b. bağış ilanları

Telefon numarası onaylama ilanları

Sonoyuncu ilanları

1.000 adetten fazla Youtube Abone satışları

Minecraft itemsadder plugin satışı

CD Key satışları

NordVPN ilanları
          </p>
        </div>

        <div className="addAdvertRules__message">
          <p>
          Alıcıların tüm ihtiyaçlarına mesajlaşma sistemi üzerinden cevap verilmesi zorunludur. Revize hakkı ve garantiyle ilgili tüm bilgiler ilan açıklamasında eksiksiz şekilde bulunmalıdır.
          </p>
          <p><strong>
            Yazı & çeviri kategorilerinde alıcıların revize hakkı bulunmaktadır;  
          </strong></p>

          <ul>
            <li>50₺ ve altı ilanlarda 2 adet revize hakkı</li>
            <li>50₺ - 100₺ arası ilanlarda 3 adet revize hakkı</li>
            <li>100₺ ve üzeri ilanlarda 5 adet revize hakkınız bulunmaktadır</li>
          </ul>

          <p>
          Alıcılarının mesajına dönüş yapmayan kişilere cezai işlem uygulanmaktadır.
          </p>

          <p>
          Hizmeti teslim etmeden önce alıcıdan sipariş onayı istemek yasaktır. Tespiti halinde hesabınız kapatılabilir. Önce hizmet teslimi yapılması zorunludur.
          </p>

          <p>
          İlan alışveriş sisteminin nasıl işlediğini tüm detaylarıyla öğrenmek için yandaki linke tıklayabilirsiniz. https://www.itemsatis.com/yardim/62/ilan-alisveris-sistemi-nasil-isliyor.html
          </p>

          <span className='addAdvertRules__message--marked'>
          Soru ve sorunlarınız için 7/24 sitenin sağ en alt bölümünden canlı desteğe bağlanabilir, destek@itemsatis.com.tr adresine e-posta gönderebilir ve destek sistemi üzerinden en üst yöneticilere bilet oluşturabilirsiniz.
          </span>

        </div>

        <label>
          <InputCheck
            chechbox
            name="rulesAgree"
            value="rulesAgree"
            onChange={() => setRulesAgree(prew => !prew)}
            checked={rulesAgree}
          />
          İlan verme kurallarını okudum, kabul ediyorum.
        </label>
    </section>
  )
}

export default AddAdvertRules