import React, { useState } from 'react'
import OtherHeaderDetail from '../components/OtherHeaderDetail'
import FooterFirstContainer from '../components/FooterFirstContainer'
import FooterSecondContainer from '../components/FooterSecondContainer'
import FooterThirdContainer from '../components/FooterThirdContainer'
import FooterEndContainer from '../components/FooterEndContainer'
import FooterPayment from '../components/FooterPayment'
import FooterContactSection from '../components/FooterContactSection'
import bannerImg from "../images/Banner-ilan-ekle.webp"
import AddAdvertFirst from '../components/addAdvert/addAdvertFirst'
import AddAdvertForm from '../components/addAdvert/AddAdvertForm'
import AddAdvertPackage from '../components/addAdvert/AddAdvertPackage'
import AddAdvertRules from '../components/addAdvert/AddAdvertRules'
import { useSelector } from 'react-redux'

const AddAdvert = () => {
    const [ currentSection, setCurrentSection ] = useState(1)
    

    function handleSection() {
        if (currentSection != 4) {
            setCurrentSection(prew => prew+1)
        } else {
            console.log("İlanı Oluşturuldu!")
        }
           
    }

  return (
    <div>
      {/* <OtherHeader otherHeaderPagesLink="Blog" otherPageBanner={otherPageBanner} banners={banners} /> */}
      <OtherHeaderDetail  otherHeaderPagesLink="İlan ekle"/>
        <main className='addAdvert container'>
            <a href="#">
              <div className="addAdvert__banner">
                  <img src={bannerImg} alt="" />
              </div>
            </a>

            {
                currentSection == 1 ? 
                <AddAdvertFirst/> :
                currentSection == 2 ? 
                <AddAdvertForm/> :
                currentSection == 3 ? 
                <AddAdvertPackage/> :
                currentSection == 4 &&
                <AddAdvertRules/> 
            }

            <div className="addAdvert__buttons">
                {
                    currentSection > 1 ?
                    <button onClick={() => setCurrentSection(prew => prew - 1)}> Önceki Adım </button> :
                    <div></div>
                }

                <button onClick={() => handleSection()}>
                    {
                        currentSection == 4 ?
                        "İlanı Oluştur!" :
                        "Sonrakı Adım"
                    }
                </button>
            </div>
        </main>
      
      <footer>
        <div className="footer_parent_container">
          <FooterFirstContainer />
          <FooterSecondContainer />
          <FooterThirdContainer />
        </div>
        <FooterPayment/>
        <FooterContactSection/>
        <FooterEndContainer />
      </footer>
    </div>
  )
}

export default AddAdvert