import React from 'react'
import giveawayLogo from '../images/giveawayLogo.webp'
import gameImg1 from '../images/pubg-mobil-60-uc-323.webp'
import gameImg2 from '../images/totally-accurate-battle-simulator-steam-cd-key-408.webp'
import gameImg3 from '../images/valorant-150-vp-789.webp'
import gameImg4 from '../images/wolfteam-350-nakit-650.webp'
import gameImg5 from '../images/google-play-25-tl-699.webp'
import gameImg6 from '../images/tl-gpay-epin-513.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRectangleList, faUserPlus } from '@fortawesome/free-solid-svg-icons'

const GiveawayCard = ({detailButton}) => {
  return (
    <article className='giveawayCard'>
        <div className="giveawayCard__heading">
          <div className="giveawayCard__logo">
            <img src={giveawayLogo} alt="" />
          </div>
          <div className="giveawayCard__heading--content">
            <span className="giveawayCard__creator">
              FollowTurk
            </span>
            <h5 className="giveawayCard__title">
              Takip edenlere özel çekiliş açıklamayı
            </h5>
          </div>
        </div>

        <p className="giveawayCard__time">
          3 gün 18 saat 44 dakika sonra sona erecek
        </p>

        <div className="giveawayCard__counts">
          <div className="giveawayCard__price">
            <strong>4,186.00 ₺</strong>
            <span>değerinde çekiliş havuzu</span>
          </div>
          <div className="giveawayCard__participant">
            <strong>1491</strong>
            <span>Katılımcı</span>
          </div>
        </div>

        <div className="giveawayCard__boxes">
          <div className="giveawayCard__box">
            <img src={gameImg1} alt="" />
            <span> 1 Kişiye </span>
          </div>
          <div className="giveawayCard__box">
            <img src={gameImg2} alt="" />
            <span> 41 Kişiye </span>
          </div>
          <div className="giveawayCard__box">
            <img src={gameImg3} alt="" />
            <span> 5 Kişiye </span>
          </div>
          <div className="giveawayCard__box">
            <img src={gameImg4} alt="" />
            <span> 8 Kişiye </span>
          </div>
          <div className="giveawayCard__box">
            <img src={gameImg5} alt="" />
            <span> 23 Kişiye </span>
          </div>
          <div className="giveawayCard__box">
            <img src={gameImg6} alt="" />
            <span> 11 Kişiye </span>
          </div>
        </div>

        <div className="giveawayCard__btn--box">
          <button className="giveawayCard__btn">
            <FontAwesomeIcon icon={faUserPlus}/>
            Çekilişe Katıl 
          </button>

          {
            detailButton && 
            <a className="giveawayCard__btn">
              <FontAwesomeIcon icon={faRectangleList}/>
              Çekiliş Detayları 
            </a>
          }

        </div>

    </article>
  )
}

export default GiveawayCard