import React from 'react'
import HeaderFourthFirst from './HeaderFourthFirst'

const HeaderFourthContainer = () => {
  return (
    <div className='header_fourth_first_container'>
        <HeaderFourthFirst/>
    </div>
  )
}

export default HeaderFourthContainer