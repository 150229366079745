import React, { useState, useRef, useEffect } from 'react';
import AzImg from "../images/azerbaijan.png";
import TrImg from "../images/turkey.png";
import { FaPlus } from "react-icons/fa6";
import { useDispatch } from 'react-redux';
import { announceCreateBtnFunc, handleLoginRegister } from '../redux/MainReducer';
import { getHighlightProducts, getMyAnnouncements, getMyFavories, getProductEl, getProducts, getProductsFilter } from '../actions/MainAction';
import HeaderSecondsButtons from './HeaderSecondsButtons'; // Importing the component
import { useNavigate } from 'react-router-dom';

const AddAnnounce = ({ isSearchVisible, toggleSearchVisibility }) => { // Receiving props
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedLanguage, setSelectedLanguage] = useState(AzImg);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage === 'az' ? AzImg : TrImg);
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  let prId = localStorage.getItem("prId")

  const handleSelectLanguage = (imgSrc, language) => {
    setSelectedLanguage(imgSrc);
    setIsOpen(false);
    localStorage.setItem('selectedLanguage', language);
    dispatch(getHighlightProducts(language));
    dispatch(getProducts(language));
    dispatch(getProductsFilter(language));
    dispatch(getProductEl(prId, language))
    dispatch(getMyFavories(language))
    dispatch(getMyAnnouncements(language))
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const addAnnounceBtn = () => {
    const token = localStorage.getItem('access_token_game');
    if (token) {
      // dispatch(announceCreateBtnFunc())
      navigate("/add-advert")

    } else {
      dispatch(handleLoginRegister())
    }
  }

  return (
    <div className="announce_lang_container">
      <HeaderSecondsButtons
        isSearchVisible={isSearchVisible}
        toggleSearchVisibility={toggleSearchVisibility}
      />
      <button onClick={addAnnounceBtn} className='add_announce_btn'><FaPlus /><span>İlan Ekle</span> </button>
      <div className="language_select_container" ref={dropdownRef}>
        <div className="selected_language" onClick={() => setIsOpen(!isOpen)}>
          <img src={selectedLanguage} alt="Selected Language" />
        </div>
        {isOpen && (
          <div className="language_options">
            <div onClick={() => handleSelectLanguage(AzImg, 'az')}>
              <img src={AzImg} alt="English" />
            </div>
            <div onClick={() => handleSelectLanguage(TrImg, 'tr')}>
              <img src={TrImg} alt="Turkish" />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AddAnnounce;
