import React from 'react'
import OtherHeaderDetail from '../components/OtherHeaderDetail'
import FooterFirstContainer from '../components/FooterFirstContainer'
import FooterSecondContainer from '../components/FooterSecondContainer'
import FooterThirdContainer from '../components/FooterThirdContainer'
import FooterEndContainer from '../components/FooterEndContainer'
import ProfileDetailLeftRightContainer from '../components/ProfileDetailLeftRightContainer'

const ProfileDetails = () => {
  return (
    <div>
      {/* <OtherHeader otherHeaderPagesLink="Blog" otherPageBanner={otherPageBanner} banners={banners} /> */}
      <OtherHeaderDetail  otherHeaderPagesLink="Blog"/>
      <main>
        <section>
          {/* <OtherGamesInput /> */}
          {/* <h2 className="section_header">Tüm Bloglar</h2>
          <OtherNewsCardsContainer /> */}
          <ProfileDetailLeftRightContainer/>
        </section>
      </main>
      <footer>
        <div className="footer_parent_container">
          <FooterFirstContainer />
          <FooterSecondContainer />
          <FooterThirdContainer />
        </div>
        <FooterEndContainer />

      </footer>
    </div>
  )
}

export default ProfileDetails