import React from 'react'

import image from '../../images/zxn7pwAqsTCEPSQUVvOaNtoK3.webp'
import image1 from '../../images/GiqRkmbfsx1groB3NaztU5wKC.webp'

const MembershipPackages = () => {
  return (
    <div className="membershipPackages">
        <h2> Üyelik Paketleri </h2>
        <p> Aşağıdaki listeden ihtiyacınıza uygun paketi satın alabilirsiniz. </p>
        <div className="membershipPackages__box">

            <a>
                <div className="membershipPackages__card">
                    <div className="membershipPackages__card--img">
                        <img src={image} alt="" />
                    </div>
                    <h5 className="membershipPackages__card--title">
                        Kullanıcı Adı Değişikliği Paketi
                    </h5>
                    <p className="membershipPackages__card--desc">
                        Kullanıcı adı değişikliği paketi ile İtemSatış kullanıcı adınızı bir takım şartları karşılıyorsanız ücret karşılığında değiştirebilirsiniz. Detaylı bilgi için lütfen paket sayfasınız ziyaret edin.
                    </p>
                </div>
            </a>

            <a>
                <div className="membershipPackages__card">
                    <div className="membershipPackages__card--img">
                        <img src={image1} alt="" />
                    </div>
                    <h5 className="membershipPackages__card--title">
                        Otomatik İlan Yukarı Taşıma Paketi
                    </h5>
                    <p className="membershipPackages__card--desc">
                        Üyeliğinize göre belirlediğiniz ilanlarınızı süresi dolduğunda otomatik olarak yukarı taşımaya yarayan bu paket için detaylı bilgiyi paket sayfasından öğrenebilirsiniz.                </p>
                </div>
            </a>

        </div>
    </div>
  )
}

export default MembershipPackages