import React from 'react'
import DealsDaysCard from './DealsDaysCard'

const DealsDaysContainer = () => {
  return (
    <div className='container deals_days_container'>
        <DealsDaysCard/>
        <DealsDaysCard/>
        <DealsDaysCard/>
        <DealsDaysCard/>
        <DealsDaysCard/>
        <DealsDaysCard/>
    </div>
  )
}

export default DealsDaysContainer