import React from 'react'

const OtherHeaderMainContainer = ({ otherPageBanner, otherHeaderPagesLink, banners }) => {
    return (
        <div className='other_header_main_container' style={{ background: `url(${banners[0]?.back})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: '100%' }}>
            <div className="other_bg_shadow">
                <div className="other_header_main_container_content container">
                    <h1 className='other_header_title'>{banners[0]?.head}</h1>
                    <p>{banners[0]?.content}</p>
                </div>

            </div>

        </div>
    )
}

export default OtherHeaderMainContainer